import React from 'react'
import styled, { css } from 'styled-components'
import footerBorder from 'img/footer/footer-border.svg'
import footerBorderSm from 'img/footer/footer-border-sm.svg'
import footerLogo from 'img/footer/logo.svg'
import deckIcon from 'img/footer/docs/deck-icon.svg'
import faqIcon from 'img/footer/docs/faq-icon.svg'
import manifestoIcon from 'img/footer/docs/manifesto-icon.svg'
import Socials from 'components/Socials'
import { DECK_LINK, FAQ_LINK, MANIFESTO_LINK } from '../../configs/app'

const Footer: React.FunctionComponent = () => {
  return (
    <Root>
      <Wrapper>
        <Container>
          <Title />
          <SocialsWrap>
            <Socials />
          </SocialsWrap>
          <Docs>
            <DeckBtn href={DECK_LINK} target={'_blank'}>
              <DeckIcon />
              <DocText>INVESTMENT DECK</DocText>
            </DeckBtn>
            <FaqBtn href={FAQ_LINK} target={'_blank'}>
              <FaqIcon />
              <DocText>FAQ</DocText>
            </FaqBtn>
            <ManifestoBtn href={MANIFESTO_LINK} target={'_blank'}>
              <ManifestoIcon />
              <DocText>L2DAO Manifesto</DocText>
            </ManifestoBtn>
          </Docs>
          <Copyright>© L2PAD, All rights can't be protected, 2021</Copyright>
        </Container>
      </Wrapper>
    </Root>
  )
}

export default Footer

const Root = styled.div`
  display: none;
  ${(props) => props.theme.adaptive.smd} {
    display: block;
    padding: 70px 0 0;
    overflow: hidden;
  }
  ${(props) => props.theme.adaptive.smd} {
    padding: 60px 0 0;
  }
  ${(props) => props.theme.adaptive.ssm} {
    padding: 50px 0 0;
  }
`

const Wrapper = styled.div`
  ${(props) => props.theme.adaptive.smd} {
    position: relative;
    width: 500px;
    margin: 0 auto;
    background: #101011;
    border-radius: 28px 28px 0 0;
  }
  ${(props) => props.theme.adaptive.sm} {
    width: 410px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    width: 320px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 9px;
    left: 10px;
    width: 480px;
    height: 461px;
    background: url('${footerBorder}') center no-repeat;
    background-size: cover;
    ${(props) => props.theme.adaptive.sm} {
      width: 392px;
      height: 461px;
      left: 9px;
      background: url('${footerBorderSm}') center no-repeat;
      background-size: cover;
    }
    ${(props) => props.theme.adaptive.ssm} {
      width: 306px;
      height: 360px;
      top: 7px;
      left: 7px;
    }
  }
`

const Container = styled.div`
  ${(props) => props.theme.adaptive.smd} {
    padding: 62px 0 26px;
    width: 333px;
    margin: 0 auto;
  }
  ${(props) => props.theme.adaptive.ssm} {
    padding: 42px 0 20px;
    width: 260px;
  }
`

const Title = styled.div`
  ${(props) => props.theme.adaptive.smd} {
    width: 100%;
    height: 40px;
    background: url('${footerLogo}') center no-repeat;
    background-size: contain;
    margin-bottom: 45px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    height: 31px;
    margin-bottom: 35px;
  }
`

const Copyright = styled.div`
  ${(props) => props.theme.adaptive.smd} {
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.065em;
    color: #ded3d3;
    opacity: 0.3;
  }
  ${(props) => props.theme.adaptive.ssm} {
    font-size: 11px;
    line-height: 14px;
  }
`

const SocialsWrap = styled.div`
  ${(props) => props.theme.adaptive.smd} {
    width: 100%;
    margin-bottom: 43px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    margin-bottom: 33px;
  }
`

const Docs = styled.div`
  ${(props) => props.theme.adaptive.smd} {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 37px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    margin-bottom: 27px;
  }
`

const DocBtn = css`
  ${(props) => props.theme.adaptive.smd} {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 232px;
    height: 38px;
    border: 1px solid rgba(130, 130, 130, 0.6);
    border-radius: 6px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    width: 181px;
    height: 30px;
    border-radius: 5px;
  }
`

const DeckBtn = styled.a`
  ${DocBtn};
  ${(props) => props.theme.adaptive.smd} {
    margin-right: 14px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    margin-right: 11px;
  }
`

const ManifestoBtn = styled.a`
  ${DocBtn};
  ${(props) => props.theme.adaptive.smd} {
    margin-top: 18px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    margin-top: 15px;
  }
`

const FaqBtn = styled.a`
  ${DocBtn};
  ${(props) => props.theme.adaptive.smd} {
    width: 87px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    width: 68px;
  }
`

const DeckIcon = styled.div`
  ${(props) => props.theme.adaptive.smd} {
    width: 15px;
    height: 15px;
    background: url('${deckIcon}') center no-repeat;
    background-size: cover;
    margin-right: 9px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    width: 12px;
    height: 12px;
    margin-right: 7px;
  }
`

const FaqIcon = styled.div`
  ${(props) => props.theme.adaptive.smd} {
    width: 14px;
    height: 18px;
    background: url('${faqIcon}') center no-repeat;
    background-size: cover;
    margin-right: 11px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    width: 11px;
    height: 14px;
    margin-right: 8px;
  }
`

const ManifestoIcon = styled.div`
  ${(props) => props.theme.adaptive.smd} {
    width: 12px;
    height: 15px;
    background: url('${manifestoIcon}') center no-repeat;
    background-size: cover;
    margin-right: 11px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    width: 9px;
    height: 12px;
    margin-right: 8px;
  }
`

const DocText = styled.div`
  ${(props) => props.theme.adaptive.smd} {
    font-family: 'Newtown';
    font-size: 18px;
    line-height: 100%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #686868;
    margin-top: 3px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    font-size: 14px;
    margin-top: 2px;
  }
`
