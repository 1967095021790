import GlobalFonts from './theme/fonts'
import GlobalStyle from './theme/globalStyle'
import Fonts from 'theme/fonts'
import Router from './pages/Router'
import { ThemeProvider } from 'styled-components'
import theme from './theme/theme'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalFonts />
      <GlobalStyle />
      <Fonts />
      <Router />
    </ThemeProvider>
  )
}

export default App
