interface Text {
  text: string
}

const Texts: Text[] = [
  {
    text: 'Permissionless',
  },
  {
    text: 'Inclusive by Design',
  },
  {
    text: 'L2 Launchpad',
  },
  {
    text: 'Quadratic IDOs',
  },
  {
    text: 'Deflationary Economics',
  },
]

export default Texts
