import { FC } from 'react'
import { ReactComponent as Twitter } from 'img/socials/twitter.svg'
import { ReactComponent as Medium } from 'img/socials/medium.svg'
import { ReactComponent as Discord } from 'img/socials/discord.svg'
import { ReactComponent as Telegram } from 'img/socials/telegram.svg'

interface Social {
  icon: FC
  link: string
  disabled?: boolean
}

const socials: Social[] = [
  {
    icon: Twitter,
    link: 'https://twitter.com/l2dao_io',
  },
  {
    icon: Medium,
    link: 'https://medium.com/l2pad',
  },
  {
    icon: Discord,
    link: 'https://discord.gg/ycf3qM5DT9',
  },
  {
    icon: Telegram,
    link: 'https://t.me/l2DAO_comm',
  },
]

export default socials
