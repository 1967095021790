import React from 'react'
import { ReactComponent as Logo } from 'img/common/logo.svg'
import styled from 'styled-components'

function Logotype() {
  return (
    <Root>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <LogoText>
        L<span>2</span>Dao
      </LogoText>
    </Root>
  )
}

export default Logotype

const Root = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  ${({ theme }) => theme.adaptive.xl} {
    gap: 10px;
  }
  ${({ theme }) => theme.adaptive.md} {
    gap: 8px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    gap: 20px;
  }
`

const LogoWrapper = styled.div`
  width: 36px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 30px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 25px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 21px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 17px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    width: 36px;
  }
`

const LogoText = styled.span`
  display: inline-block;
  font-size: 31px;
  letter-spacing: 0.04em;
  font-family: 'Rexagon';
  color: #fff;
  text-transform: uppercase;
  margin-top: -3px;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 27px;
    margin-top: 0;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 23px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 20px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 15px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    font-size: 40px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 30px;
  }
  span {
    background: linear-gradient(180deg, #dea268 13.96%, #e77141 92.94%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`
