import { EDOM } from 'constants'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import TimerImage from './TimerImage'
import getPercentage from '../../../../helpers/getPercentage'
import { START_DATE, END_DATE } from 'configs/app'

function Timer() {
  const [percentage] = useState(getPercentage(START_DATE, END_DATE))

  return (
    <Root>
      <TopRow>
        <TitleRow>
          <Title>Loading start</Title>
          <Triangle />
          <Triangle />
          <Triangle />
        </TitleRow>
        <ProgressRow>
          <Triangle />
          <Progress>{`${Math.ceil(percentage)}%`}</Progress>
          <Triangle />
        </ProgressRow>
      </TopRow>
      <TimerImageWrapper>
        <TimerImage />
      </TimerImageWrapper>
    </Root>
  )
}

export default Timer

const Root = styled.div`
  display: inline-block;
  ${({ theme }) => theme.adaptive.sm} {
    width: 330px;
    margin: 0 auto;
  }
  ${({ theme }) => theme.adaptive.ssm} {
    width: 260px;
    margin: 0 auto;
  }
`

const Title = styled.div`
  font-family: 'Rexagon';
  text-transform: uppercase;
  font-size: 15px;
  color: #fff;
  text-shadow: 0px 0px 24.4574px rgba(255, 255, 255, 0.47),
    0px 0px 5.14894px rgba(255, 255, 255, 0.25);
  padding-left: 20px;
  margin-right: 10px;
  ${(props) => props.theme.adaptive.xl} {
    font-size: 13px;
  }
  ${(props) => props.theme.adaptive.lg} {
    font-size: 11px;
    padding-left: 16px;
    margin-right: 8px;
  }
  ${(props) => props.theme.adaptive.slg} {
    font-size: 10px;
    padding-left: 14px;
    margin-right: 7px;
  }
  ${(props) => props.theme.adaptive.md} {
    font-size: 7px;
    margin-right: 5px;
  }
  ${(props) => props.theme.adaptive.smd} {
    font-size: 20px;
    line-height: 19px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 13px;
    line-height: 12px;
  }
  ${({ theme }) => theme.adaptive.ssm} {
    font-size: 10px;
    line-height: 9px;
  }
`

const TitleRow = styled.div`
  display: flex;
  align-items: center;
`

const TopRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  ${(props) => props.theme.adaptive.lg} {
    margin-bottom: 3px;
  }
  ${(props) => props.theme.adaptive.slg} {
    margin-bottom: 2px;
  }
  ${(props) => props.theme.adaptive.md} {
    margin-bottom: 1px;
  }
  ${(props) => props.theme.adaptive.smd} {
    margin-bottom: 3px;
  }
`

interface TriangleProps {
  color?: string
}

const Triangle = styled.div<TriangleProps>`
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid #665e5d;
  margin-right: 4px;
  ${(props) => props.theme.adaptive.xl} {
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-left: 5px solid #665e5d;
  }
  ${(props) => props.theme.adaptive.lg} {
    margin-right: 3px;
  }
  ${(props) => props.theme.adaptive.slg} {
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    border-left: 4px solid #665e5d;
    margin-right: 2px;
  }
  ${(props) => props.theme.adaptive.md} {
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-left: 3px solid #665e5d;
    margin-right: 1px;
  }
  ${(props) => props.theme.adaptive.smd} {
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-left: 7px solid #665e5d;
    margin-right: 2px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    border-left: 5px solid #665e5d;
    margin-right: 2px;
  }
  ${({ theme }) => theme.adaptive.ssm} {
    border-left: 4px solid #665e5d;
  }
  &:last-child {
    margin-right: 0;
  }
`

const ProgressRow = styled.div`
  display: flex;
  align-items: center;
  ${Triangle} {
    border-left-color: #e2905b;
    &:last-child {
      margin-right: 0;
      margin-left: 9px;
      ${(props) => props.theme.adaptive.lg} {
        margin-left: 7px;
      }
      ${(props) => props.theme.adaptive.slg} {
        margin-left: 7px;
      }
      ${(props) => props.theme.adaptive.md} {
        margin-left: 5px;
      }
      ${(props) => props.theme.adaptive.smd} {
        margin-left: 7px;
      }
    }
    &:first-child {
      margin-right: 9px;
      ${(props) => props.theme.adaptive.lg} {
        margin-right: 7px;
      }
      ${(props) => props.theme.adaptive.slg} {
        margin-right: 6px;
      }
      ${(props) => props.theme.adaptive.md} {
        margin-right: 5px;
      }
      ${(props) => props.theme.adaptive.smd} {
        margin-right: 6px;
      }
    }
  }
`

const Progress = styled.div`
  font-size: 18px;
  font-weight: 700;
  text-shadow: 0px 0px 28.7744px rgba(227, 139, 86, 0.62),
    0px 0px 5.23171px rgba(227, 139, 86, 0.62);
  color: #e1965f;
  ${(props) => props.theme.adaptive.xl} {
    font-size: 14px;
  }
  ${(props) => props.theme.adaptive.slg} {
    font-size: 12px;
  }
  ${(props) => props.theme.adaptive.md} {
    font-size: 9px;
  }
  ${(props) => props.theme.adaptive.smd} {
    font-size: 23px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 15px;
  }
  ${({ theme }) => theme.adaptive.ssm} {
    font-size: 12px;
  }
`

const Total = styled.span`
  position: absolute;
  right: 24px;
  top: 10px;
  font-size: 16px;
  font-weight: 700;
  color: #85848a;
  font-family: 'Rexagon';
  text-transform: uppercase;
  ${(props) => props.theme.adaptive.xl} {
    font-size: 14px;
    right: 20px;
    top: 9px;
  }
  ${(props) => props.theme.adaptive.lg} {
    font-size: 12px;
    right: 18px;
    top: 8px;
  }
  ${(props) => props.theme.adaptive.slg} {
    font-size: 10px;
    top: 7px;
    right: 16px;
  }
  ${(props) => props.theme.adaptive.md} {
    font-size: 8px;
    top: 5px;
  }
  ${(props) => props.theme.adaptive.smd} {
    font-size: 23px;
    line-height: 22px;
    right: 26px;
    top: 13px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 15px;
    line-height: 14px;
    right: 16px;
    top: 8px;
  }
  ${({ theme }) => theme.adaptive.ssm} {
    font-size: 10px;
    line-height: 10px;
    right: 14px;
    top: 6px;
  }
`

const TimerImageWrapper = styled.div`
  position: relative;
  svg {
    width: 100%;
    height: auto;
  }
  ${(props) => props.theme.adaptive.xl} {
    max-width: 348px;
  }
  ${(props) => props.theme.adaptive.lg} {
    max-width: 298px;
  }
  ${(props) => props.theme.adaptive.slg} {
    max-width: 257px;
  }
  ${(props) => props.theme.adaptive.md} {
    max-width: 191px;
  }
  ${(props) => props.theme.adaptive.smd} {
    max-width: 500px;
    width: 500px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 100%;
  }
`
