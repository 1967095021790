import React, { useState } from 'react'
import styled from 'styled-components'
import { Container } from 'theme/theme'
import teamList, { TeamProps } from 'store/teamList'
import telegramIcon from 'img/team/telegram.svg'
import bgImg from 'img/team/bg.png'
import Slider from 'react-slick'
import useWindowSize from 'helpers/utils/useWindowSize'

interface PersonProps {
  active?: boolean
}

const Team: React.FunctionComponent = () => {
  const [active, setActive] = useState(0)
  const [activePerson, setActivePerson] = useState(teamList[0])
  const { width } = useWindowSize()

  const Items = teamList.map((item, idx) => {
    return (
      <Person
        key={idx}
        active={active === idx}
        onClick={() => onChoose(item, idx)}
      >
        <NoActiveBg active={active === idx} />
        <img src={item.img} />
      </Person>
    )
  })

  const settings = {
    centerMode: false,
    infinite: false,
    speed: 500,
    arrows: false,
    rows: 2,
    slidesPerRow: 1,
    slidesToShow: 3,
    swipeToSlide: true,
    variableWidth: true,
  }

  const MobSlider = (
    <MobileSlider>
      <Slider {...settings} className="slider">
        {Items}
      </Slider>
    </MobileSlider>
  )

  const onChoose = (item: TeamProps, idx: number) => {
    setActive(idx)
    setActivePerson(item)
  }

  return (
    <Root>
      <Container>
        <TitleRow>
          <Title>The L2DAO Legion</Title>
          <SubTitle>
            Traveller, the interface below enables you to see the L2DAO Legion,
            the core humans who are making the L2DAO come true.
          </SubTitle>
        </TitleRow>
        <ListRow>
          <List>{width <= 619 ? MobSlider : Items}</List>
          <Info>
            <Line />
            <Row>
              <InfoImg>
                {' '}
                <img src={activePerson.img} />
              </InfoImg>
              <InfoAbout>
                <Name>{activePerson.name}</Name>
                <InfoTelegram
                  href={activePerson.telegramLink}
                  target={'_blank'}
                >
                  {activePerson.telegram}
                </InfoTelegram>
              </InfoAbout>
            </Row>
            <Text>{activePerson.text}</Text>
            <BlurBlock>
              <Blur />
              <Blur />
            </BlurBlock>
          </Info>
        </ListRow>
      </Container>
    </Root>
  )
}

export default Team

const Root = styled.div`
  position: relative;
  width: 85%;
  padding: 130px 0 0 0;
  padding-bottom: calc(1 * (10vh + 10vw)) !important;
  ${(props) => props.theme.adaptive.xl} {
    padding: 106px 0 0 0;
  }
  ${(props) => props.theme.adaptive.lg} {
    padding: 84px 0 0 0;
  }
  ${(props) => props.theme.adaptive.slg} {
    padding: 54px 0 0 0;
  }
  ${(props) => props.theme.adaptive.md} {
    padding: 40px 0 0 0;
  }
  ${(props) => props.theme.adaptive.smd} {
    width: 100%;
    padding: 70px 0 70px 0;
    padding-bottom: 70px !important;
  }
  ${(props) => props.theme.adaptive.sm} {
    width: 380px;
    margin: 0 auto;
    padding: 60px 0 60px 0;
    padding-bottom: 60px !important;
  }
  ${(props) => props.theme.adaptive.ssm} {
    width: 290px;
    padding: 50px 0 50px 0;
    padding-bottom: 50px !important;
  }
  .slick-slide {
    width: 104px !important;
    margin: 7px;
    ${(props) => props.theme.adaptive.ssm} {
      width: 73px !important;
      margin: 5px;
    }
  }
  ${Container} {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 1280px;
      height: 679px;
      top: -130px;
      left: 58px;
      background: url('${bgImg}') center no-repeat;
      background-size: cover;
      ${(props) => props.theme.adaptive.xl} {
        width: 1120px;
        height: 594px;
        top: -106px;
        left: 51px;
      }
      ${(props) => props.theme.adaptive.lg} {
        width: 960px;
        height: 509px;
        top: -84px;
        left: 43px;
      }
      ${(props) => props.theme.adaptive.slg} {
        width: 827px;
        height: 439px;
        top: -54px;
        left: 37px;
      }
      ${(props) => props.theme.adaptive.md} {
        width: 613px;
        height: 325px;
        top: -40px;
        left: 27px;
      }
      ${(props) => props.theme.adaptive.smd} {
        width: 737px;
        height: 391px;
        top: 55px;
        left: 50%;
        transform: translateX(-50%);
      }
      ${(props) => props.theme.adaptive.sm} {
        top: 148px;
        left: calc(50% + 75px);
      }
      ${(props) => props.theme.adaptive.ssm} {
        width: 517px;
        height: 274px;
        top: 142px;
        left: calc(50% + 50px);
      }
    }
  }
`

const TitleRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 36px;
  ${(props) => props.theme.adaptive.xl} {
    margin-bottom: 31px;
  }
  ${(props) => props.theme.adaptive.lg} {
    margin-bottom: 30px;
  }
  ${(props) => props.theme.adaptive.slg} {
    margin-bottom: 26px;
  }
  ${(props) => props.theme.adaptive.md} {
    margin-bottom: 19px;
  }
  ${(props) => props.theme.adaptive.smd} {
    display: block;
    margin-bottom: 26px;
  }
  ${(props) => props.theme.adaptive.sm} {
    margin-bottom: 44px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    margin-bottom: 27px;
  }
`

const Title = styled.div`
  font-family: 'Rexagon';
  font-size: 52px;
  line-height: 137%;
  text-transform: uppercase;
  color: #ffffff;
  margin-right: 29px;
  ${(props) => props.theme.adaptive.lg} {
    font-size: 48px;
    margin-right: 27px;
  }
  ${(props) => props.theme.adaptive.slg} {
    font-size: 44px;
    margin-right: 63px;
  }
  ${(props) => props.theme.adaptive.md} {
    font-size: 32px;
    margin-right: 20px;
  }
  ${(props) => props.theme.adaptive.smd} {
    font-size: 55px;
    line-height: 52px;
    margin-right: 0;
    margin-bottom: 6px;
  }
  ${(props) => props.theme.adaptive.sm} {
    font-size: 54px;
    line-height: 51px;
    margin-bottom: 14px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    font-size: 42px;
    line-height: 40px;
    margin-bottom: 15px;
  }
`

const SubTitle = styled.div`
  font-family: 'Panton';
  max-width: 869px;
  font-size: 21px;
  line-height: 117%;
  letter-spacing: 0.02em;
  color: #ffffff;
  ${(props) => props.theme.adaptive.xl} {
    max-width: 700px;
    font-size: 18px;
    line-height: 24px;
  }
  ${(props) => props.theme.adaptive.lg} {
    max-width: 566px;
    font-size: 17px;
    line-height: 22px;
  }
  ${(props) => props.theme.adaptive.slg} {
    max-width: 441px;
    font-size: 15px;
    line-height: 22px;
  }
  ${(props) => props.theme.adaptive.md} {
    max-width: 348px;
    font-size: 12px;
    line-height: 17px;
  }
  ${(props) => props.theme.adaptive.smd} {
    max-width: unset;
    width: 100%;
    font-size: 17px;
    line-height: 28px;
  }
  ${(props) => props.theme.adaptive.sm} {
    font-size: 17px;
    line-height: 28px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    font-size: 14px;
    line-height: 22px;
  }
`

const ListRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  ${(props) => props.theme.adaptive.slg} {
    align-items: center;
  }
  ${(props) => props.theme.adaptive.md} {
    align-items: center;
    justify-content: flex-start;
  }
  ${(props) => props.theme.adaptive.smd} {
    display: block;
  }
`

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 820px;
  margin: -7px;
  ${(props) => props.theme.adaptive.xl} {
    width: 720px;
  }
  ${(props) => props.theme.adaptive.lg} {
    width: 620px;
  }
  ${(props) => props.theme.adaptive.slg} {
    width: 535px;
    margin: -6px;
  }
  ${(props) => props.theme.adaptive.md} {
    width: 350px;
    margin: -6px -3px;
  }
  ${(props) => props.theme.adaptive.smd} {
    width: 510px;
    margin: -9px -5px;
  }
  ${(props) => props.theme.adaptive.sm} {
    width: 100%;
    margin: 0;
  }
`

const Person = styled.div<PersonProps>`
  position: relative;
  width: 150px;
  height: 150px;
  margin: 7px;
  cursor: pointer;
  border-radius: 3px;
  ${(props) => props.theme.adaptive.xl} {
    width: 130px;
    height: 130px;
  }
  ${(props) => props.theme.adaptive.lg} {
    width: 110px;
    height: 110px;
    border-radius: 2px;
  }
  ${(props) => props.theme.adaptive.slg} {
    width: 95px;
    height: 95px;
    margin: 6px;
  }
  ${(props) => props.theme.adaptive.md} {
    width: 64px;
    height: 64px;
    margin: 6px 3px;
  }
  ${(props) => props.theme.adaptive.smd} {
    width: 92px;
    height: 92px;
    margin: 9px 5px;
  }
  ${(props) => props.theme.adaptive.sm} {
    width: 104px;
    height: 104px;
    margin: 0;
  }
  ${(props) => props.theme.adaptive.ssm} {
    width: 73px;
    height: 73px;
    border-radius: 1px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: #ff8d4f;
    box-shadow: 0px 0px 7px rgba(255, 141, 79, 0.9);
    border-radius: 5px;
    transition: 0.4s;
    opacity: ${({ active }) => (active ? '1' : '0')};
    ${(props) => props.theme.adaptive.xl} {
      border-radius: 4px;
      box-shadow: 0px 0px 6px rgba(255, 141, 79, 0.9);
    }
    ${(props) => props.theme.adaptive.lg} {
      border-radius: 4px;
      box-shadow: 0px 0px 5px rgba(255, 141, 79, 0.9);
      height: 2px;
    }
    ${(props) => props.theme.adaptive.slg} {
      border-radius: 3px;
      box-shadow: 0px 0px 4px rgba(255, 141, 79, 0.9);
    }
    ${(props) => props.theme.adaptive.md} {
      border-radius: 2px;
      height: 1px;
      box-shadow: 0px 0px 3px rgba(255, 141, 79, 0.9);
    }
    ${(props) => props.theme.adaptive.smd} {
      height: 2px;
    }
    ${(props) => props.theme.adaptive.sm} {
      border-radius: 3px;
      box-shadow: 0px 0px 4px rgba(255, 141, 79, 0.9);
    }
    ${(props) => props.theme.adaptive.ssm} {
      box-shadow: 0px 0px 3px rgba(255, 141, 79, 0.9);
    }
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
`
const NoActiveBg = styled.div<PersonProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: 0.4s;
  opacity: ${({ active }) => (active ? '0' : '1')};
  pointer-events: none;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
`

const Info = styled.div`
  position: relative;
  width: 496px;
  height: 341px;
  padding: 25px 50px;
  background: rgba(0, 0, 0, 0.7);
  margin-left: 50px;
  ${(props) => props.theme.adaptive.xl} {
    width: 434px;
    height: 298px;
    padding: 22px 44px;
    margin-left: 45px;
  }
  ${(props) => props.theme.adaptive.lg} {
    width: 372px;
    height: 256px;
    padding: 19px 38px;
    margin-left: 40px;
  }
  ${(props) => props.theme.adaptive.slg} {
    width: 320px;
    height: 250px;
    padding: 23px 32px;
    margin-left: 34px;
  }
  ${(props) => props.theme.adaptive.md} {
    width: 283px;
    height: 181px;
    padding: 17px 16px 17px 24px;
    margin-left: 20px;
  }
  ${(props) => props.theme.adaptive.smd} {
    width: 100%;
    height: 157px;
    padding: 17px 24px 17px 24px;
    margin-left: 0;
    margin-top: 32px;
  }
  ${(props) => props.theme.adaptive.sm} {
    width: 100%;
    height: 383px;
    padding: 36px 26px;
    margin-top: 56px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    height: 291px;
    padding: 25px 18px;
    margin-top: 36px;
  }
`

const Line = styled.div`
  width: 100%;
  height: 3px;
  background: #ff8d4f;
  box-shadow: 0px 0px 7px rgba(255, 141, 79, 0.9);
  border-radius: 5px;
  margin-bottom: 37px;
  ${(props) => props.theme.adaptive.xl} {
    box-shadow: 0px 0px 6px rgba(255, 141, 79, 0.9);
    border-radius: 4px;
    margin-bottom: 32px;
    height: 3px;
  }
  ${(props) => props.theme.adaptive.lg} {
    box-shadow: 0px 0px 5px rgba(255, 141, 79, 0.9);
    border-radius: 4px;
    margin-bottom: 28px;
    height: 2px;
  }
  ${(props) => props.theme.adaptive.slg} {
    box-shadow: 0px 0px 4px rgba(255, 141, 79, 0.9);
    border-radius: 3px;
    margin-bottom: 20px;
  }
  ${(props) => props.theme.adaptive.md} {
    box-shadow: 0px 0px 3px rgba(255, 141, 79, 0.9);
    border-radius: 2px;
    margin-bottom: 14px;
  }
  ${(props) => props.theme.adaptive.smd} {
    position: absolute;
    left: 24px;
    top: 0;
    width: 165px;
    height: 2px;
    margin-bottom: 0;
  }
  ${(props) => props.theme.adaptive.sm} {
    left: 26px;
    width: 300px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    left: 18px;
    width: 220px;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  ${(props) => props.theme.adaptive.xl} {
    margin-bottom: 22px;
  }
  ${(props) => props.theme.adaptive.lg} {
    margin-bottom: 19px;
  }
  ${(props) => props.theme.adaptive.slg} {
    margin-bottom: 20px;
  }
  ${(props) => props.theme.adaptive.md} {
    margin-bottom: 15px;
  }
  ${(props) => props.theme.adaptive.smd} {
    margin-bottom: 13px;
  }
  ${(props) => props.theme.adaptive.sm} {
    margin-bottom: 32px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    margin-bottom: 22px;
  }
`

const InfoImg = styled.div`
  width: 68px;
  height: 68px;
  margin-right: 22px;
  ${(props) => props.theme.adaptive.xl} {
    width: 60px;
    height: 60px;
    margin-right: 19px;
  }
  ${(props) => props.theme.adaptive.lg} {
    width: 51px;
    height: 51px;
    margin-right: 17px;
  }
  ${(props) => props.theme.adaptive.slg} {
    width: 44px;
    height: 44px;
    margin-right: 14px;
  }
  ${(props) => props.theme.adaptive.md} {
    width: 33px;
    height: 33px;
    margin-right: 11px;
  }
  ${(props) => props.theme.adaptive.smd} {
    width: 33px;
    height: 33px;
    margin-right: 11px;
  }
  ${(props) => props.theme.adaptive.sm} {
    width: 57px;
    height: 57px;
    margin-right: 18px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    width: 40px;
    height: 40px;
    margin-right: 13px;
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`

const InfoAbout = styled.div``

const Name = styled.div`
  font-family: 'Panton';
  font-weight: bold;
  font-size: 30px;
  line-height: 33px;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin-bottom: 4px;
  ${(props) => props.theme.adaptive.xl} {
    font-size: 26px;
    line-height: 29px;
    margin-bottom: 4px;
  }
  ${(props) => props.theme.adaptive.lg} {
    font-size: 24px;
    line-height: 25px;
    margin-bottom: 3px;
  }
  ${(props) => props.theme.adaptive.slg} {
    font-size: 21px;
    line-height: 21px;
    margin-bottom: 2px;
  }
  ${(props) => props.theme.adaptive.md} {
    font-size: 15px;
    line-height: 16px;
    margin-bottom: 2px;
  }
  ${(props) => props.theme.adaptive.smd} {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 1px;
  }
  ${(props) => props.theme.adaptive.sm} {
    font-size: 25px;
    line-height: 27px;
    margin-bottom: 2px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 1px;
  }
`

const InfoTelegram = styled.a`
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #28a8e8;
  margin-left: 24px;
  text-decoration: none;
  outline: none;
  ${(props) => props.theme.adaptive.xl} {
    font-size: 16px;
    line-height: 21px;
    margin-left: 21px;
  }
  ${(props) => props.theme.adaptive.lg} {
    font-size: 14px;
    line-height: 18px;
    margin-left: 18px;
  }
  ${(props) => props.theme.adaptive.slg} {
    font-size: 12px;
    line-height: 15px;
    margin-left: 16px;
  }
  ${(props) => props.theme.adaptive.md} {
    font-size: 10px;
    line-height: 11px;
    margin-left: 11px;
  }
  ${(props) => props.theme.adaptive.smd} {
    font-size: 11px;
    line-height: 11px;
    margin-left: 12px;
  }
  ${(props) => props.theme.adaptive.sm} {
    font-size: 16px;
    line-height: 20px;
    margin-left: 21px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    font-size: 12px;
    line-height: 14px;
    margin-left: 15px;
  }
  &:before {
    content: '';
    position: absolute;
    left: -24px;
    top: 4px;
    width: 15px;
    height: 13px;
    background: url('${telegramIcon}') center no-repeat;
    background-size: cover;
    ${(props) => props.theme.adaptive.xl} {
      left: -21px;
      top: 4px;
      width: 13px;
      height: 11px;
    }
    ${(props) => props.theme.adaptive.lg} {
      left: -18px;
      top: 3px;
      width: 11px;
      height: 10px;
    }
    ${(props) => props.theme.adaptive.slg} {
      left: -16px;
      top: 2px;
      width: 10px;
      height: 9px;
    }
    ${(props) => props.theme.adaptive.md} {
      left: -11px;
      top: 2px;
      width: 7px;
      height: 6px;
    }
    ${(props) => props.theme.adaptive.smd} {
      left: -11px;
      top: 1px;
      width: 9px;
      height: 7px;
    }
    ${(props) => props.theme.adaptive.sm} {
      left: -21px;
      top: 3px;
      width: 16px;
      height: 12px;
    }
    ${(props) => props.theme.adaptive.ssm} {
      left: -15px;
      top: 2px;
      width: 11px;
      height: 9px;
    }
  }
`

const Text = styled.div`
  font-family: 'Panton';
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  ${(props) => props.theme.adaptive.xl} {
    font-size: 16px;
    line-height: 18px;
  }
  ${(props) => props.theme.adaptive.lg} {
    font-size: 14px;
    line-height: 17px;
  }
  ${(props) => props.theme.adaptive.slg} {
    font-size: 14px;
    line-height: 17px;
  }
  ${(props) => props.theme.adaptive.md} {
    font-size: 12px;
    line-height: 14px;
  }
  ${(props) => props.theme.adaptive.smd} {
    font-size: 13px;
    line-height: 18px;
  }
  ${(props) => props.theme.adaptive.sm} {
    font-size: 18px;
    line-height: 28px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    font-size: 14px;
    line-height: 22px;
  }
`

const MobileSlider = styled.div`
  position: relative;
  width: 100vw;
`

const BlurBlock = styled.div`
  ${(props) => props.theme.adaptive.smd} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 305px;
    z-index: -1;
  }
  ${(props) => props.theme.adaptive.sm} {
    width: 213px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    width: 158px;
  }
`

const Blur = styled.div`
  ${(props) => props.theme.adaptive.smd} {
    width: 100%;
    height: 36px;
    background: #4ca6f9;
    filter: blur(71.875px);
    margin-bottom: 30px;
    &:last-child {
      margin: 0;
    }
  }
  ${(props) => props.theme.adaptive.sm} {
    height: 88px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    height: 66px;
    filter: blur(50.4079px);
  }
`
