import React from 'react'
import socialsList from 'store/socials'
import styled from 'styled-components'

function Socials() {
  return (
    <Root>
      {socialsList.map((social, idx) => {
        if (social.disabled) {
          return (
            <SocialItem disabled={true} key={idx}>
              <social.icon />
            </SocialItem>
          )
        } else {
          return (
            <SocialItem href={social.link} target="_blank" key={idx}>
              <social.icon />
            </SocialItem>
          )
        }
      })}
    </Root>
  )
}

export default Socials

export const Root = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: calc(1.4 * (2vh + 2vw));
  margin: 0 auto;
  gap: 10px;
  ${({ theme }) => theme.adaptive.slg} {
    width: calc(2 * (2vh + 2vw));
  }
`

interface SocialItemProps {
  disabled?: boolean
}

const SocialItem = styled.a<SocialItemProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(0.58 * (2vh + 2vw));
  height: calc(0.58 * (2vh + 2vw));
  border: 1px solid #272728;
  border-radius: 6px;
  opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
  svg {
    max-width: 40%;
    max-height: 85%;
    fill: #535457;
  }
`
