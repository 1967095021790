import React, { useEffect, useRef } from 'react'
import useResize from 'helpers/utils/useResize'
import { breakpoints } from 'theme/theme'
import { setHeight } from 'store/monitor'

export interface MonitorImageProps {
  onResize?({}): void
  onCreate?({}): void
}

function MonitorImage({ onResize, onCreate }: MonitorImageProps) {
  const monitorRef = useRef<any>(null)
  const { height, width } = useResize()

  useEffect(() => {
    if (!onCreate) return
    onCreate({
      height: monitorRef.current.getBoundingClientRect().height,
      width: monitorRef.current.getBoundingClientRect().width,
    })
  }, [])

  useEffect(() => {
    if (!width || !height || !monitorRef.current) return
    if (width > breakpoints.smd.w && height > breakpoints.smd.h) {
      // setHeight(monitorRef.current.getBoundingClientRect().height)
      if (!onResize) return
      onResize({
        height: monitorRef.current.getBoundingClientRect().height,
        width: monitorRef.current.getBoundingClientRect().width,
      })
    }
    // if (monitorRef?.current && height) {
    //   const monitorHeight = monitorRef.current.getBBox().height
    //   if (monitorHeight + 150 > height) {
    //     imageWrapperRef.current.style.height = `${height}px`
    //     imageWrapperRef.current.style.width = `auto`
    //   } else {
    //     imageWrapperRef.current.style.height = `auto`
    //     imageWrapperRef.current.style.width = `100%`
    //   }
    // }
  }, [height, width])

  return (
    <svg
      viewBox="0 0 1920 950"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
    >
      <mask
        id="mask0"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="1920"
        height="950"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1920 0H0V950H1920V0ZM150.618 125.66C114.959 127.641 87.3707 148.49 82.7708 183.906C76.4556 232.527 70 323.353 70 491.5C70 661.568 76.604 752.538 82.9871 800.744C87.5479 835.187 114.092 855.551 148.777 857.581C232.186 862.464 428.392 870 845 870C1255.16 870 1451.69 862.696 1537.27 857.81C1574.25 855.699 1602.68 833.485 1607.35 796.737C1613.63 747.388 1620 656.625 1620 491.5C1620 328.298 1613.78 237.736 1607.57 188.004C1602.87 150.277 1573.52 127.515 1535.56 125.449C1445.85 120.567 1242.66 113 845 113C441.588 113 238.312 120.788 150.618 125.66Z"
          fill="#C4C4C4"
        />
      </mask>
      <g mask="url(#mask0)">
        <path d="M-4 0H1916V950H-4V0Z" fill="#101011" ref={monitorRef} />
        <g opacity="0.5">
          <path
            d="M784.894 912.106V898.76H783V914H792.514V912.106H784.894ZM788.704 905.444L787.594 907.338H789.815L788.704 905.444Z"
            fill="white"
          />
          <path
            d="M784.894 912.106V898.76H783V914H792.514V912.106H784.894ZM788.704 905.444L787.594 907.338H789.815L788.704 905.444Z"
            fill="url(#paint0_linear)"
            fillOpacity="0.2"
          />
          <path
            d="M801.292 914H807.519L800.813 898.781L794.129 914H800.334V912.106H797.025L800.813 903.528L804.601 912.106H801.292V914Z"
            fill="white"
          />
          <path
            d="M801.292 914H807.519L800.813 898.781L794.129 914H800.334V912.106H797.025L800.813 903.528L804.601 912.106H801.292V914Z"
            fill="url(#paint1_linear)"
            fillOpacity="0.2"
          />
          <path
            d="M806.967 898.76L811.605 906.402L811.779 906.663V914H813.673V906.663L813.847 906.402L809.188 898.76H806.967ZM816.264 898.76L813.281 903.658L814.392 905.487L818.485 898.76H816.264Z"
            fill="white"
          />
          <path
            d="M806.967 898.76L811.605 906.402L811.779 906.663V914H813.673V906.663L813.847 906.402L809.188 898.76H806.967ZM816.264 898.76L813.281 903.658L814.392 905.487L818.485 898.76H816.264Z"
            fill="url(#paint2_linear)"
            fillOpacity="0.2"
          />
          <path
            d="M832.763 898.76H821.333V914H832.763V912.106H823.227V900.675H832.763V898.76ZM828.953 907.338V905.444H824.185V907.338H828.953Z"
            fill="white"
          />
          <path
            d="M832.763 898.76H821.333V914H832.763V912.106H823.227V900.675H832.763V898.76ZM828.953 907.338V905.444H824.185V907.338H828.953Z"
            fill="url(#paint3_linear)"
            fillOpacity="0.2"
          />
          <path
            d="M841.292 907.338C843.665 907.338 845.581 905.422 845.581 903.049C845.581 900.697 843.665 898.76 841.292 898.76H836.066V914H837.96V900.675H841.292C842.62 900.675 843.686 901.742 843.686 903.049C843.686 904.377 842.62 905.444 841.292 905.444H838.875V908.513L843.164 914.022H845.581L840.377 907.338H841.292Z"
            fill="white"
          />
          <path
            d="M841.292 907.338C843.665 907.338 845.581 905.422 845.581 903.049C845.581 900.697 843.665 898.76 841.292 898.76H836.066V914H837.96V900.675H841.292C842.62 900.675 843.686 901.742 843.686 903.049C843.686 904.377 842.62 905.444 841.292 905.444H838.875V908.513L843.164 914.022H845.581L840.377 907.338H841.292Z"
            fill="url(#paint4_linear)"
            fillOpacity="0.2"
          />
          <path
            d="M858.458 905.857V903.049C858.458 900.697 856.542 898.76 854.169 898.76H853.211C850.86 898.76 848.944 900.697 848.944 903.049H850.838C850.838 901.742 851.905 900.675 853.211 900.675H854.169C855.476 900.675 856.564 901.742 856.564 903.049V905.857L849.815 911.039C849.271 911.387 848.944 911.997 848.944 912.65V914H858.458V912.106H851.557L857.587 907.447C858.132 907.098 858.458 906.489 858.458 905.857Z"
            fill="white"
          />
          <path
            d="M858.458 905.857V903.049C858.458 900.697 856.542 898.76 854.169 898.76H853.211C850.86 898.76 848.944 900.697 848.944 903.049H850.838C850.838 901.742 851.905 900.675 853.211 900.675H854.169C855.476 900.675 856.564 901.742 856.564 903.049V905.857L849.815 911.039C849.271 911.387 848.944 911.997 848.944 912.65V914H858.458V912.106H851.557L857.587 907.447C858.132 907.098 858.458 906.489 858.458 905.857Z"
            fill="url(#paint5_linear)"
            fillOpacity="0.2"
          />
          <path
            d="M875.754 898.76H870.529V914H872.423V900.675H875.754C877.083 900.675 878.149 901.742 878.149 903.049C878.149 904.377 877.083 905.444 875.754 905.444H873.381V907.338H875.754C878.128 907.338 880.044 905.422 880.044 903.049C880.044 900.697 878.128 898.76 875.754 898.76Z"
            fill="white"
          />
          <path
            d="M875.754 898.76H870.529V914H872.423V900.675H875.754C877.083 900.675 878.149 901.742 878.149 903.049C878.149 904.377 877.083 905.444 875.754 905.444H873.381V907.338H875.754C878.128 907.338 880.044 905.422 880.044 903.049C880.044 900.697 878.128 898.76 875.754 898.76Z"
            fill="url(#paint6_linear)"
            fillOpacity="0.2"
          />
          <path
            d="M887.227 914H893.453L886.748 898.781L880.063 914H886.269V912.106H882.959L886.748 903.528L890.536 912.106H887.227V914Z"
            fill="white"
          />
          <path
            d="M887.227 914H893.453L886.748 898.781L880.063 914H886.269V912.106H882.959L886.748 903.528L890.536 912.106H887.227V914Z"
            fill="url(#paint7_linear)"
            fillOpacity="0.2"
          />
          <path
            d="M903.086 898.76H896.446V914H898.34V900.675H903.086C904.676 900.675 905.96 901.96 905.96 903.549V909.232C905.96 910.821 904.676 912.106 903.086 912.106H899.298V914H903.086C905.721 914 907.876 911.866 907.876 909.232V903.549C907.876 900.915 905.721 898.76 903.086 898.76ZM902.15 905.444L901.04 907.338H903.26L902.15 905.444Z"
            fill="white"
          />
          <path
            d="M903.086 898.76H896.446V914H898.34V900.675H903.086C904.676 900.675 905.96 901.96 905.96 903.549V909.232C905.96 910.821 904.676 912.106 903.086 912.106H899.298V914H903.086C905.721 914 907.876 911.866 907.876 909.232V903.549C907.876 900.915 905.721 898.76 903.086 898.76ZM902.15 905.444L901.04 907.338H903.26L902.15 905.444Z"
            fill="url(#paint8_linear)"
            fillOpacity="0.2"
          />
        </g>
        <path
          opacity="0.5"
          d="M1878.43 141.845C1879.09 144.572 1879.42 147.368 1879.42 150.174L1879.42 799.029C1879.42 817.351 1865.44 832.649 1847.2 834.303L1546.42 861.566C1540.2 862.13 1533.94 861.039 1528.28 858.403L108.272 197.458C92.9953 190.347 84.8431 173.457 88.7802 157.072L114.814 48.7251C118.639 32.8047 132.879 21.5815 149.252 21.5815L978.833 21.5815L1640 21.5815L1821.46 21.5815C1837.81 21.5815 1852.04 32.7769 1855.88 48.6713L1878.43 141.845Z"
          stroke="#4D4D4D"
          strokeWidth="0.837031"
        />
        <path
          d="M82.8569 182.938C87.4299 148.08 114.523 127.676 149.624 125.715C236.771 120.847 440.056 113 845 113C1242.66 113 1445.85 120.567 1535.56 125.449C1573.52 127.515 1602.87 150.277 1607.57 188.004C1613.78 237.736 1620 328.298 1620 491.5C1620 656.625 1613.63 747.388 1607.35 796.737C1602.68 833.485 1574.25 855.699 1537.27 857.81C1451.69 862.696 1255.16 870 845 870C426.848 870 230.735 862.409 147.854 857.527C113.726 855.517 87.6047 835.576 83.0725 801.69C76.663 753.769 70 662.765 70 491.5C70 322.155 76.5145 231.282 82.8569 182.938Z"
          stroke="#484A4B"
          strokeWidth="2"
        />
        <g opacity="0.8" filter="url(#filter0_f)">
          <path
            d="M149.233 118.726C111.485 120.834 81.0141 143.17 75.9163 182.027C69.5165 230.809 63 322.028 63 491.5C63 662.892 69.665 754.25 76.1343 802.618C81.1937 840.446 110.634 862.347 147.443 864.515C230.517 869.408 426.784 877 845 877C1255.22 877 1451.9 869.695 1537.67 864.799C1577.41 862.53 1609.13 838.285 1614.3 797.62C1620.63 747.854 1627 656.761 1627 491.5C1627 328.162 1620.77 237.277 1614.52 187.137C1609.32 145.455 1576.65 120.675 1535.94 118.46C1446.06 113.568 1242.73 106 845 106C439.976 106 236.554 113.848 149.233 118.726Z"
            stroke="black"
            strokeWidth="14"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f"
          x="19"
          y="62"
          width="1652"
          height="859"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="18.5" result="effect1_foregroundBlur" />
        </filter>
        <linearGradient
          id="paint0_linear"
          x1="845.438"
          y1="898.76"
          x2="845.438"
          y2="914.022"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="845.438"
          y1="898.76"
          x2="845.438"
          y2="914.022"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="845.438"
          y1="898.76"
          x2="845.438"
          y2="914.022"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="845.438"
          y1="898.76"
          x2="845.438"
          y2="914.022"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="845.438"
          y1="898.76"
          x2="845.438"
          y2="914.022"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="845.438"
          y1="898.76"
          x2="845.438"
          y2="914.022"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1="845.438"
          y1="898.76"
          x2="845.438"
          y2="914.022"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint7_linear"
          x1="845.438"
          y1="898.76"
          x2="845.438"
          y2="914.022"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint8_linear"
          x1="845.438"
          y1="898.76"
          x2="845.438"
          y2="914.022"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default MonitorImage
