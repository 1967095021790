import { createGlobalStyle } from 'styled-components'
import PantonWoff2Bold from './fonts/Panton/Panton-Bold.woff2'
import PantonWoffBold from './fonts/Panton/Panton-Bold.woff'
import PantonWoff2Regular from './fonts/Panton/Panton-Regular.woff2'
import PantonWoffRegular from './fonts/Panton/Panton-Regular.woff'
import RexagonWoff2Regular from './fonts/Rexagon/RexagonRegular.woff2'
import RexagonWoffRegular from './fonts/Rexagon/RexagonRegular.woff'
import HeliosWoffRegular from './fonts/Helios/HeliosRegular.woff'
import HeliosWoff2Regular from './fonts/Helios/HeliosRegular.woff2'
import NewtownWoffRegular from './fonts/Newtown/Newtown.woff'
import NewtownWoff2Regular from './fonts/Newtown/Newtown.woff2'

// Panton
const panton = {
  regular: {
    woff2: PantonWoff2Regular,
    woff: PantonWoffRegular,
  },
  bold: {
    woff2: PantonWoff2Bold,
    woff: PantonWoffBold,
  },
}

// Newtown
const newtown = {
  regular: {
    woff2: NewtownWoff2Regular,
    woff: NewtownWoffRegular,
  },
}

// Rexagon
const rexagon = {
  regular: {
    woff2: RexagonWoff2Regular,
    woff: RexagonWoffRegular,
  },
}

// Helios
const helios = {
  regular: {
    woff2: HeliosWoff2Regular,
    woff: HeliosWoffRegular,
  },
}

const Fonts = createGlobalStyle`
  @font-face {
    font-family: 'Rexagon';
    src: local('Rexagon'),
         url(${rexagon.regular.woff2}) format('woff2'),
         url(${rexagon.regular.woff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Panton';
    src: local('Panton'),
         url(${panton.regular.woff2}) format('woff2'),
         url(${panton.regular.woff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Panton';
    src: local('Panton'),
         url(${panton.bold.woff2}) format('woff2'),
         url(${panton.bold.woff}) format('woff');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'Helios';
    src: local('Helios'),
         url(${helios.regular.woff2}) format('woff2'),
         url(${helios.regular.woff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Newtown';
    src: local('Newtown'),
    url(${newtown.regular.woff2}) format('woff2'),
    url(${newtown.regular.woff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
`

export default Fonts
