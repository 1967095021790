import { createGlobalStyle } from 'styled-components'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body, html, #root {
    min-height: 100vh;
    background: #1a1315;
  }

  body, button {
    font-family: 'Panton';
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
  }
  /*  */

  button {
    border: 0;
    background: none;
    padding: 0;
    outline: none;
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }

  .main-container {
    /* overflow-y: auto; */
  }
`
export default GlobalStyle
