import { createStore, createEvent } from 'effector'

var AudioContext =
  window.AudioContext || // Default
  window.webkitAudioContext || // Safari and old versions of Chrome
  false
let ctx = null
if (AudioContext) {
  // Do whatever you want using the Web Audio API
  ctx = new AudioContext()
  // ...
} else {
  // Web Audio API is not supported
  // Alert the user
  alert(
    'Sorry, but the Web Audio API is not supported by your browser. Please, consider upgrading to the latest version or downloading Google Chrome or Mozilla Firefox'
  )
}

export const $volume = createStore<number>(-0.5)

export const $audioContext = createStore<any>(ctx)

export const $sourceBuffer = createStore<AudioBufferSourceNode>(
  $audioContext.getState().createBufferSource()
)

export const $buffer = createStore<AudioBuffer | null>(null)

export const $isPlaying = createStore<boolean>(false)
export const $isLoaded = createStore<boolean>(false)

export const $osc = createStore<OscillatorNode>(
  $audioContext.getState().createOscillator()
)

export const $gainNode = createStore<GainNode>(
  $audioContext.getState().createGain()
)

export const setVolume = createEvent<number>()
export const setAudioContext = createEvent<any>()
export const setSourceBuffer = createEvent<any>()
export const setBuffer = createEvent<any>()
export const setIsPlaying = createEvent<boolean>()
export const setIsLoaded = createEvent<boolean>()

$volume.on(setVolume, (state, volume) => volume)
$audioContext.on(setAudioContext, (state, context) => context)
$sourceBuffer.on(setSourceBuffer, (state, source) => source)
$buffer.on(setBuffer, (state, buffer) => buffer)
$isPlaying.on(setIsPlaying, (state, isPlaying) => isPlaying)
$isLoaded.on(setIsLoaded, (state, isLoaded) => isLoaded)

$volume.watch((state) => {
  const context = $audioContext.getState()
  $gainNode.getState().gain.setValueAtTime(-1 + state, context.currentTime)
})
