import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import tiersList from '../../store/tiersList'
import { Container } from '../../theme/theme'
import Tier from './Tier'
import arrowConnect from 'img/tier/arrow-connect.svg'
import background from 'img/tier/background.png'
import backgroundSmd from 'img/tier/background-smd.png'
import backgroundSm from 'img/tier/background-sm.png'
import backgroundSsm from 'img/tier/background-ssm.png'
import cornerSm from 'img/tier/corner-sm.svg'
import cornerBotSm from 'img/tier/bot-corner-sm.svg'
import cornerSsm from 'img/tier/corner-ssm.svg'
import { STAKING_LINK } from 'configs/app'

const Tiers: React.FunctionComponent = () => {
  const [active, setActive] = useState(0)

  return (
    <Root id={'tier'}>
      <Container>
        <Content>
          <ZIndexWrapper>
            <ListWrapper>
              <List>
                {tiersList.map((item, idx) => (
                  <Tier data={item} active={idx === active} key={idx} />
                ))}
              </List>
            </ListWrapper>
            <Bottom>
              <PaddingWrapper>
                <Tabs>
                  {tiersList.map((item, idx) => {
                    return (
                      <TabItem
                        active={active === idx}
                        key={idx}
                        onClick={() => setActive(idx)}
                        type="button"
                      >
                        <span>{item.title}</span>
                      </TabItem>
                    )
                  })}
                </Tabs>
              </PaddingWrapper>
              <Text>
                L2DAO’s algorithmic tier structure ensures{' '}
                <strong>maximum utility for the greatest number</strong> at all
                times offering unparalleled internal economics scalability.
              </Text>
              <PaddingWrapper>
                <GetTier>
                  <GetTierButton
                    onClick={() => window.open(STAKING_LINK, '_blank')}
                  >
                    GET YOUR TIER
                  </GetTierButton>
                  <GetTierDescr>
                    The parameters defining the tier dynamics are governed by
                    the L2DAO.
                  </GetTierDescr>
                </GetTier>
              </PaddingWrapper>
              <ConnectBlock idx={active}>
                <TopCorner />
                <ConnectionLine />
                <BotCorner />
              </ConnectBlock>
            </Bottom>
          </ZIndexWrapper>
        </Content>
      </Container>
    </Root>
  )
}

export default Tiers

interface LightProps {
  color?: string
}

interface TabItemProps {
  active?: boolean
}

interface Line {
  idx?: number
}

const TabItem = styled.button<TabItemProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 30px;
  border-radius: 4px;
  font-size: 13px;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
  margin-left: 25px;
  border: 1px solid #423e3f;
  ${(props) => props.theme.adaptive.xl} {
    width: 63px;
    height: 26px;
    border-radius: 3px;
    font-size: 12px;
    margin-left: 21px;
  }
  ${(props) => props.theme.adaptive.lg} {
    width: 54px;
    height: 23px;
    font-size: 11px;
    margin-left: 18px;
  }
  ${(props) => props.theme.adaptive.slg} {
    width: 47px;
    height: 20px;
    font-size: 9px;
    margin-left: 16px;
  }
  ${(props) => props.theme.adaptive.md} {
    width: 39px;
    height: 16px;
    font-size: 8px;
    margin-left: 13px;
  }
  ${(props) => props.theme.adaptive.smd} {
    width: 67px;
    height: 27px;
    font-size: 14px;
    margin-left: 23px;
  }
  ${(props) => props.theme.adaptive.sm} {
    width: 73px;
    height: 30px;
    font-size: 16px;
    margin-left: 0;
    margin-top: 65px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    width: 61px;
    height: 25px;
    font-size: 13px;
    margin-top: 54px;
  }
  &:first-child {
    margin: 0;
  }
  ${({ active }) => {
    if (active)
      return css`
        border: 1px solid #ec7f30;
        box-shadow: 0px 0px 7px rgba(236, 181, 48, 0.3),
          0px 0px 17px rgba(236, 181, 48, 0.3);
        ${(props) => props.theme.adaptive.xl} {
          box-shadow: 0px 0px 6px rgba(236, 181, 48, 0.3),
            0px 0px 15px rgba(236, 181, 48, 0.3);
        }
        ${(props) => props.theme.adaptive.lg} {
          box-shadow: 0px 0px 5px rgba(236, 181, 48, 0.3),
            0px 0px 13px rgba(236, 181, 48, 0.3);
        }
        ${(props) => props.theme.adaptive.slg} {
          box-shadow: 0px 0px 4px rgba(236, 181, 48, 0.3),
            0px 0px 11px rgba(236, 181, 48, 0.3);
        }
        ${(props) => props.theme.adaptive.md} {
          box-shadow: 0px 0px 4px rgba(236, 181, 48, 0.3),
            0px 0px 9px rgba(236, 181, 48, 0.3);
        }
        ${(props) => props.theme.adaptive.smd} {
          box-shadow: 0px 0px 6px rgba(236, 181, 48, 0.3),
            0px 0px 16px rgba(236, 181, 48, 0.3);
        }
        ${(props) => props.theme.adaptive.sm} {
          box-shadow: 0px 0px 7px rgba(236, 181, 48, 0.3),
            0px 0px 17px rgba(236, 181, 48, 0.3);
        }
        ${(props) => props.theme.adaptive.ssm} {
          box-shadow: 0px 0px 6px rgba(236, 181, 48, 0.3),
            0px 0px 15px rgba(236, 181, 48, 0.3);
        }
      `
  }}
  span {
    position: relative;
    top: 1px;
  }
`

const ListWrapper = styled.div`
  /* display: inline-block; */
`

const Tabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
  ${(props) => props.theme.adaptive.xl} {
    margin-top: 28px;
  }
  ${(props) => props.theme.adaptive.lg} {
    margin-top: 26px;
  }
  ${(props) => props.theme.adaptive.slg} {
    margin-top: 23px;
  }
  ${(props) => props.theme.adaptive.md} {
    margin-top: 19px;
  }
  ${(props) => props.theme.adaptive.smd} {
    margin-top: 33px;
  }
  ${(props) => props.theme.adaptive.sm} {
    position: absolute;
    right: 0;
    bottom: calc(100% + 43px);
    flex-direction: column;
    margin-top: 0;
  }
  ${(props) => props.theme.adaptive.ssm} {
    bottom: calc(100% + 36px);
  }
`

const Text = styled.p`
  font-size: 21px;
  line-height: 33px;
  letter-spacing: 0.02em;
  color: #fff;
  padding-left: 30px;
  margin: 51px 0 37px 0;
  ${(props) => props.theme.adaptive.xl} {
    font-size: 18px;
    line-height: 29px;
    padding-left: 27px;
    margin: 45px 0 33px 0;
  }
  ${(props) => props.theme.adaptive.lg} {
    font-size: 15px;
    line-height: 25px;
    padding-left: 23px;
    margin: 38px 0 28px 0;
  }
  ${(props) => props.theme.adaptive.slg} {
    font-size: 13px;
    line-height: 21px;
    padding-left: 21px;
    margin: 33px 0 24px 0;
  }
  ${(props) => props.theme.adaptive.md} {
    font-size: 9px;
    line-height: 16px;
    padding-left: 16px;
    margin: 24px 0 14px 0;
  }
  ${(props) => props.theme.adaptive.smd} {
    font-size: 17px;
    line-height: 28px;
    padding-left: 27px;
    margin: 42px 0 25px 0;
  }
  ${(props) => props.theme.adaptive.sm} {
    font-size: 17px;
    line-height: 23px;
    padding-left: 0px;
    margin: 43px 0 23px 0;
  }
  ${(props) => props.theme.adaptive.ssm} {
    font-size: 14px;
    line-height: 19px;
    margin: 36px 0 20px 0;
  }
`

const Content = styled.div`
  width: 531px;
  margin-left: auto;
  ${(props) => props.theme.adaptive.xl} {
    width: 465px;
  }
  ${(props) => props.theme.adaptive.lg} {
    width: 398px;
  }
  ${(props) => props.theme.adaptive.slg} {
    width: 343px;
  }
  ${(props) => props.theme.adaptive.md} {
    width: 284px;
  }
  ${(props) => props.theme.adaptive.smd} {
    width: 500px;
    margin: 0 auto;
  }
  ${(props) => props.theme.adaptive.sm} {
    width: 333px;
    margin: 0 auto;
  }
  ${(props) => props.theme.adaptive.ssm} {
    width: 260px;
  }
  &:before {
    content: '';
    position: absolute;
    left: -220px;
    top: -209px;
    width: 1350px;
    height: 781px;
    background: url(${background}) no-repeat center;
    background-size: cover;
    ${(props) => props.theme.adaptive.xl} {
      left: -225px;
      top: -195px;
      width: 1223px;
      height: 718px;
    }
    ${(props) => props.theme.adaptive.lg} {
      left: -180px;
      top: -168px;
      width: 1048px;
      height: 616px;
    }
    ${(props) => props.theme.adaptive.slg} {
      left: -144px;
      top: -142px;
      width: 903px;
      height: 531px;
    }
    ${(props) => props.theme.adaptive.md} {
      left: -39px;
      top: -87px;
      width: 671px;
      height: 394px;
    }
    ${(props) => props.theme.adaptive.smd} {
      left: calc(50% + 10px);
      top: -310px;
      transform: translateX(-50%);
      width: 892px;
      height: 1041px;
      background: url(${backgroundSmd}) no-repeat center;
    }
    ${(props) => props.theme.adaptive.sm} {
      top: -290px;
      width: 842px;
      height: 1170px;
      background: url(${backgroundSm}) no-repeat center;
    }
    ${(props) => props.theme.adaptive.ssm} {
      top: -215px;
      width: 658px;
      height: 943px;
      background: url(${backgroundSsm}) no-repeat center;
    }
  }
`

const ZIndexWrapper = styled.div`
  position: relative;
  z-index: 3;
`

const PaddingWrapper = styled.div`
  padding: 0 40px;
  ${(props) => props.theme.adaptive.xl} {
    padding: 0 27px;
  }
  ${(props) => props.theme.adaptive.lg} {
    padding: 0 23px;
  }
  ${(props) => props.theme.adaptive.slg} {
    padding: 0 21px;
  }
  ${(props) => props.theme.adaptive.md} {
    padding: 0 16px;
  }
  ${(props) => props.theme.adaptive.smd} {
    padding: 0 27px;
  }
  ${(props) => props.theme.adaptive.sm} {
    padding: 0;
  }
`

const Bottom = styled.div`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 13px;
    width: 55px;
    height: 230px;
    background: url(${arrowConnect}) no-repeat center;
    background-size: cover;
    ${(props) => props.theme.adaptive.xl} {
      width: 55px;
      height: 208px;
      top: 7px;
    }
    ${(props) => props.theme.adaptive.lg} {
      width: 47px;
      height: 178px;
    }
    ${(props) => props.theme.adaptive.slg} {
      width: 41px;
      height: 153px;
      top: 11px;
    }
    ${(props) => props.theme.adaptive.md} {
      width: 30px;
      height: 114px;
      top: 5px;
    }
    ${(props) => props.theme.adaptive.smd} {
      width: 52px;
      height: 197px;
      top: 9px;
    }
    ${(props) => props.theme.adaptive.sm} {
      display: none;
    }
  }
`

const ConnectBlock = styled.div<Line>`
  ${(props) => props.theme.adaptive.sm} {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: ${({ idx }) => (idx ? css`calc(580px - ${idx} * 95px)` : '580px')};
    bottom: 100px;
    right: -30px;
    z-index: -1;
  }
  ${(props) => props.theme.adaptive.ssm} {
    height: ${({ idx }) => (idx ? css`calc(506px - ${idx} * 79px)` : '506px')};
    bottom: 107px;
    right: -15px;
  }
`

const TopCorner = styled.div`
  ${(props) => props.theme.adaptive.sm} {
    width: 44px;
    height: 39px;
    background: url('${cornerSm}') center no-repeat;
  }
  ${(props) => props.theme.adaptive.ssm} {
    width: 15px;
    height: 31px;
    background: url('${cornerSsm}') center no-repeat;
  }
`

const ConnectionLine = styled.div`
  width: 1px;
  flex: 1 1 0%;
  background: linear-gradient(
    rgba(232, 113, 65, 0) -0.06%,
    #e87141 49.99%,
    rgba(232, 113, 65, 0) 100%
  );
  box-shadow: 0px 0px 16.5502px #e77645;
`

const BotCorner = styled.div`
  ${(props) => props.theme.adaptive.sm} {
    width: 63px;
    height: 50px;
    background: url('${cornerBotSm}') center no-repeat;
  }
  ${(props) => props.theme.adaptive.ssm} {
    width: 47px;
    height: 41px;
    background-size: contain;
  }
`

const GetTier = styled.div`
  display: flex;
  align-items: center;
  ${(props) => props.theme.adaptive.sm} {
    display: block;
  }
`

const GetTierButton = styled.button`
  position: relative;
  width: 213px;
  height: 53px;
  font-size: 16px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  &:disabled {
    opacity: 0.5;
    color: rgba(255, 255, 255, 0.5);
    cursor: not-allowed;
    &:before {
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.00001),
        rgba(255, 255, 255, 0.3),
        rgba(255, 255, 255, 0.00001)
      );
    }
    &:after {
      border-top-color: rgba(255, 255, 255, 0.3);
    }
  }
  ${(props) => props.theme.adaptive.xl} {
    width: 186px;
    height: 46px;
    font-size: 14px;
    border-radius: 9px;
  }
  ${(props) => props.theme.adaptive.lg} {
    width: 160px;
    height: 40px;
    font-size: 12px;
    border-radius: 8px;
  }
  ${(props) => props.theme.adaptive.slg} {
    width: 138px;
    height: 34px;
    font-size: 10px;
    border-radius: 7px;
  }
  ${(props) => props.theme.adaptive.md} {
    width: 102px;
    height: 25px;
    font-size: 8px;
    border-radius: 5px;
  }
  ${(props) => props.theme.adaptive.smd} {
    width: 176px;
    height: 44px;
    font-size: 14px;
    border-radius: 9px;
  }
  ${(props) => props.theme.adaptive.sm} {
    display: block;
    width: 227px;
    height: 56px;
    margin: 0 auto;
    font-size: 18px;
    border-radius: 11px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    width: 189px;
    height: 47px;
    font-size: 15px;
    border-radius: 9px;
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
  }
  &:before {
    content: '';
    position: absolute;
    width: calc(100% + 2px);
    height: 1px;
    top: -1px;
    left: -1px;
    border-radius: 10px;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.00001),
      #ec7f30,
      rgba(255, 255, 255, 0.00001)
    );
  }
  &:after {
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top-width: 7px;
    border-top-style: solid;
    border-top-color: #ec7f30;
    ${(props) => props.theme.adaptive.xl} {
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top-width: 6px;
    }
    ${(props) => props.theme.adaptive.lg} {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top-width: 5px;
    }
    ${(props) => props.theme.adaptive.md} {
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top-width: 4px;
    }
    ${(props) => props.theme.adaptive.smd} {
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top-width: 6px;
    }
    ${(props) => props.theme.adaptive.sm} {
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top-width: 7px;
    }
    ${(props) => props.theme.adaptive.ssm} {
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top-width: 6px;
    }
  }
`

const GetTierDescr = styled.div`
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #757172;
  max-width: 278px;
  margin-left: 40px;
  flex-shrink: 0;
  ${(props) => props.theme.adaptive.xl} {
    font-size: 13px;
    line-height: 21px;
    max-width: 243px;
    margin-left: 35px;
  }
  ${(props) => props.theme.adaptive.lg} {
    font-size: 12px;
    line-height: 18px;
    max-width: 209px;
    margin-left: 30px;
  }
  ${(props) => props.theme.adaptive.slg} {
    line-height: 15px;
    max-width: 180px;
    margin-left: 26px;
  }
  ${(props) => props.theme.adaptive.md} {
    font-size: 10px;
    line-height: 11px;
    max-width: 141px;
    margin-left: 19px;
  }
  ${(props) => props.theme.adaptive.smd} {
    font-size: 17px;
    line-height: 20px;
    max-width: 244px;
    margin-left: 33px;
  }
  ${(props) => props.theme.adaptive.sm} {
    max-width: 100%;
    margin-left: 0;
    margin-top: 30px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    margin-top: 25px;
  }
`

const Root = styled.div`
  position: relative;
  width: 85%;
  padding: 183px 0 103px 0;
  background: #1a1315;
  ${(props) => props.theme.adaptive.xl} {
    padding: 160px 0 95px 0;
  }
  ${(props) => props.theme.adaptive.lg} {
    padding: 132px 0 90px 0;
  }
  ${(props) => props.theme.adaptive.slg} {
    padding: 118px 0 85px 0;
  }
  ${(props) => props.theme.adaptive.md} {
    padding: 100px 0 80px 0;
  }
  ${(props) => props.theme.adaptive.smd} {
    width: 100%;
    padding: 190px 0 130px 0;
  }
  ${(props) => props.theme.adaptive.sm} {
    padding: 190px 0 50px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    padding: 104px 0 95px;
  }
  &:before {
    content: '';
    position: absolute;
    top: -135px;
    left: calc(50% + 20px);
    transform: translateX(-50%);
    width: 1851px;
    height: 1078px;
    ${(props) => props.theme.adaptive.xl} {
      top: -142px;
      width: 1620px;
      height: 942px;
      background-size: cover;
    }
    ${(props) => props.theme.adaptive.lg} {
      top: -120px;
      width: 1388px;
      height: 808px;
    }
    ${(props) => props.theme.adaptive.slg} {
      top: -105px;
      width: 1196px;
      height: 696px;
    }
    ${(props) => props.theme.adaptive.md} {
      top: -78px;
      width: 888px;
      height: 516px;
    }
    ${(props) => props.theme.adaptive.smd} {
      top: -50px;
      transform: translateX(-50%);
    }
    ${(props) => props.theme.adaptive.sm} {
      width: 580px;
      height: 388px;
    }
    ${(props) => props.theme.adaptive.ssm} {
      left: calc(50% + 10px);
      width: 529px;
      height: 228px;
    }
  }
  ${Container} {
    z-index: auto;
  }
`

const List = styled.div`
  position: relative;
`
