import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import noiseBg from '../../img/common/noise.png'
import scratchImg from '../../img/common/scratch.png'

const RetroCinema: React.FunctionComponent = () => (
  <Root>
    <CinemaBg>
      <OuterScratch />
      {/* <InnerScratch /> */}
      <Grain />
    </CinemaBg>
  </Root>
)

export default RetroCinema

const Root = styled.div`
  pointer-events: none;
`

const InnerScratchAnimation = keyframes`
  0% {transform: translateX(0);opacity: 0.08;}
  10% {transform: translateX(-1%);}
  20% {transform: translateX(1%);}
  30% {transform: translateX(-2%);}
  40% {transform: translateX(3%);}
  50% {transform: translateX(-3%);opacity: 0.06;}
  60% {transform: translateX(8%);}
  70% {transform: translateX(-3%);}
  80% {transform: translateX(10%);opacity: 0.03;}
  90% {transform: translateX(20%);}
  100% {transform: translateX(30%);}
`

const ScratchAnimation = keyframes`
  0%, 100% {transform: translateX(0);opacity: 0.075;}
  10% {transform: translateX(-1%);}
  20% {transform: translateX(1%);}
  30% {transform: translateX(-2%);opacity: 0.09;}
  40% {transform: translateX(3%);}
  50% {transform: translateX(-3%);opacity: 0.05;}
  60% {transform: translateX(8%);}
  70% {transform: translateX(-3%);}
  80% {transform: translateX(10%);opacity: 0.02;}
  90% {transform: translateX(-2%);}
`

const GrainAnimation = keyframes`
  0%, 100% {transform: translate(0, 0, 0);}
  10% {transform: translate(-1%, -1%);}
  20% {transform: translate(1%, 1%);}
  30% {transform: translate(-2%, -2%);}
  40% {transform: translate(3%, 3%);}
  50% {transform: translate(-3%, -3%);}
  60% {transform: translate(4%, 4%);}
  70% {transform: translate(-4%, -4%);}
  80% {transform: translate(2%, 2%);}
  90% {transform: translate(-3%, -3%);}
`

const CinemaBg = styled.div``

const Scratch = css`
  width: 120%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding-left: 100px;
  opacity: 0.05;
  background: url(${scratchImg}) repeat center center;
  animation: ${ScratchAnimation} 0.4s steps(1) infinite;
  z-index: 2;
`

const OuterScratch = styled.div`
  ${Scratch};
`

const Grain = styled.div`
  width: 110%;
  height: 110%;
  position: fixed;
  top: -5%;
  left: -5%;
  opacity: 0.11;
  background: url(${noiseBg}) repeat center center;
  animation: ${GrainAnimation} 1.5s steps(1) infinite;
  z-index: 2;
`

const InnerScratch = styled.div`
  ${Scratch};
  left: 30%;
  animation: ${InnerScratchAnimation} 4s infinite;
`
