import neuromancerImg from 'img/team/persons/neuromancer.png'
import avaImg from 'img/team/persons/ava.png'
import lucyImg from 'img/team/persons/lucy.png'
import hiroImg from 'img/team/persons/hiro.png'
import merovingianImg from 'img/team/persons/merovingian.png'
import architectImg from 'img/team/persons/architect.png'
import armitageImg from 'img/team/persons/armitage.png'
import seraphImg from 'img/team/persons/seraph.png'

export interface TeamProps {
  img: string
  name: string
  telegram: string
  telegramLink: string
  text: string
}

const TeamList: TeamProps[] = [
  {
    img: neuromancerImg,
    name: 'Neuromancer',
    telegram: '@the_neuromancerL2P',
    telegramLink: 'https://t.me/the_neuromancerL2P',
    text: 'Neuromancer is the CEO, and co-founder of the L2DAO team. He holds a masters degree in the Quantum Blockchain field, as well as a bachelor in Temporal Science. Stunningly, he also has over 50 years of experience in the Ethereum ecosystem.',
  },
  {
    img: avaImg,
    name: 'Ava Rinzler',
    telegram: '@Ava_Rinzler',
    telegramLink: 'https://t.me/Ava_Rinzler',
    text: 'Ava has spent more than 20 years innovating in the blockchain field while living and working on Moonbase Alpha I —one of the biggest crypto hubs of the 2040’s. Her affinity towards temporal phenomena is what brought Ava and the rest of the L2DAO team together.',
  },
  {
    img: lucyImg,
    name: 'Lucy',
    telegram: '@CyberLucy',
    telegramLink: 'https://t.me/CyberLucy',
    text: 'Lucy is the youngest L2DAO member that has a unique perspective on problems and new developments that are crucial for the advancement of the L2DAO ecosystem. She holds a masters in Crypto-Economics and Temporal Science.',
  },
  {
    img: hiroImg,
    name: 'Hiro',
    telegram: '@H1R0L2P4D',
    telegramLink: 'https://t.me/H1R0L2P4D',
    text: 'Hiro, a true crypto veteran, has been programming in Solidity for the better part of the past 60 years. Hiro was a robotics expert, however, during the time of the 2017 crypto crash, he got involved in the blockchain field.',
  },
  {
    img: merovingianImg,
    name: 'The Merovingian',
    telegram: '@merovingian_l2p',
    telegramLink: 'https://t.me/merovingian_l2p',
    text: 'The Merovingian is a unique individual that spent most of his past life travelling and exploring the solar system, while doing odd jobs here and there. With no formal education he still excels in all things blockchain, as well as pretty much everything else.',
  },
  {
    img: architectImg,
    name: 'The Architect',
    telegram: '@TheArchitect39',
    telegramLink: 'https://t.me/TheArchitect39',
    text: 'The Architect was born in one of the most populated areas on Earth. Neo-Mombasa was a Concrete Hive, and the ‘verse was the only thing there was to ‘do’. These circumstances turned him to be one of the most knowledgeable individuals on blockchain tech.',
  },
  {
    img: armitageImg,
    name: 'Armitage',
    telegram: '@armitagel2pad',
    telegramLink: 'https://t.me/armitagel2pad',
    text: 'As a former captain of the Terran Forces, which he abandoned for good reasons, he has all the qualities necessary for a leadership position. Keeping the team on maximum efficiency and overseeing the whole operation are his primary duties.',
  },
  {
    img: seraphImg,
    name: 'Seraph',
    telegram: '@SeraphL2P',
    telegramLink: 'https://t.me/SeraphL2P',
    text: 'Seraph is the jack of all trades for the L2DAO team. If there is anything the team needs additional manpower for, Seraph is right there ready for action. He has won countless technology contests in his life including the 1st prize in the Solar Solidity Championship.',
  },
]

export default TeamList
