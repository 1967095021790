interface Path {
  path: string
}

const Paths: Path[] = [
  {
    path: 'M7 29H19L34 5H22L7 29Z',
  },
  {
    path: 'M24 29H36L51 5H39L24 29Z',
  },
  {
    path: 'M41 29H53L68 5H56L41 29Z',
  },
  {
    path: 'M58 29H70L85 5H73L58 29Z',
  },
  {
    path: 'M75 29H87L102 5H90L75 29Z',
  },
  {
    path: 'M92 29H104L119 5H107L92 29Z',
  },
  {
    path: 'M109 29H121L136 5H124L109 29Z',
  },
  {
    path: 'M126 29H138L153 5H141L126 29Z',
  },
  {
    path: 'M143 29H155L170 5H158L143 29Z',
  },
  {
    path: 'M160 29H172L187 5H175L160 29Z',
  },
  {
    path: 'M177 29H189L204 5H192L177 29Z',
  },
  {
    path: 'M194 29H206L221 5H209L194 29Z',
  },
  {
    path: 'M211 29H223L238 5H226L211 29Z',
  },
  {
    path: 'M228 29H240L255 5H243L228 29Z',
  },
  {
    path: 'M245 29H257L272 5H260L245 29Z',
  },
  {
    path: 'M262 29H274L289 5H277L262 29Z',
  },
  {
    path: 'M279 29H291L306 5H294L279 29Z',
  },
  {
    path: 'M296 29H308L323 5H311L296 29Z',
  },
  {
    path: 'M313 29H325L340 5H328L313 29Z',
  },
  {
    path: 'M330 29H342L357 5H345L330 29Z',
  },
  {
    path: 'M347 29H359L374 5H362L347 29Z',
  },
  {
    path: 'M364 29H376L391 5H379L364 29Z',
  },
]

export default Paths
