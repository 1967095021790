import React from 'react'
import styled from 'styled-components'
import Button, { Root as ButtonElement } from './Button'
import Socials, { Root as SocialsRoot } from './Socials'
import VolumeMeter, { Root as VolumeterRoot } from '../VolumeMeter/VolumeMeter'
import { useStore } from 'effector-react'
import { $audioContext } from '../../store/audio'
import { DECK_LINK, MANIFESTO_LINK, APP_LINK } from '../../configs/app'

function Controls() {
  const context = useStore($audioContext)

  return (
    <Root>
      {context && <VolumeMeter />}
      <Button
        name="Go to app"
        onClick={() => window.open(APP_LINK, '_blank')}
        color="light"
      />
      <Button
        name="download L2DAO DECK"
        onClick={() => window.open(DECK_LINK, '_blank')}
        color="yellow"
      />
      <Button
        name="download L2DAO Manifesto"
        onClick={() => window.open(MANIFESTO_LINK, '_blank')}
        color="blue"
      />
      <Socials />
      {/* <Button
        name="THE REVOLUTION IS DETERMINISTIC"
        disabled={true}
        onClick={() => console.log('dsfds')}
        color="green"
      /> */}
    </Root>
  )
}

export default Controls

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 2%;
  top: 0;
  height: 100%;
  width: 13.5%;
  padding-top: 3.7%;
  text-align: center;
  ${ButtonElement} {
    /* margin-bottom: calc(0.7 * (2vh + 2vw)); */
    margin-bottom: calc(0.09 * (15vh + 15vw));
    ${({ theme }) => theme.adaptive.slg} {
      margin-bottom: calc(0.075 * (15vh + 15vw));
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  ${SocialsRoot} {
    margin-bottom: calc(0.75 * (2vh + 2vw));
    margin-top: calc(0.3 * (2vh + 2vw));
    ${({ theme }) => theme.adaptive.slg} {
      margin-bottom: calc(0.6 * (2vh + 2vw));
      margin-top: calc(0.2 * (2vh + 2vw));
    }
  }
  ${VolumeterRoot} {
    margin-bottom: calc(1 * (2vh + 2vw));
    padding-right: 8%;
    ${({ theme }) => theme.adaptive.slg} {
      margin-bottom: calc(0.8 * (2vh + 2vw));
    }
  }
`
