import React from 'react'
import { NavLink } from 'react-router-dom'
import navigationsList from 'store/navigation'
import styled from 'styled-components'
import navigationBg from 'img/common/navigation-sm.svg'
import buttonLine from 'img/mobcontrols/btn-line.svg'
import { APP_LINK } from 'configs/app'

function Navigation() {
  return (
    <Root>
      <MobileButton href={APP_LINK} target="_blank">
        <span>Go to App</span>
      </MobileButton>
      {navigationsList.map((navigation, idx) => {
        if (navigation.path) {
          return (
            <NavLink to={navigation.path} activeClassName="active" key={idx}>
              <NavigationItem>{navigation.title}</NavigationItem>
            </NavLink>
          )
        } else if (navigation.link) {
          return (
            <ExternalLink href={navigation.link} target="_blank" key={idx}>
              <NavigationItem>{navigation.title}</NavigationItem>
            </ExternalLink>
          )
        }
      })}
    </Root>
  )
}

export default Navigation

const NavigationItem = styled.span`
  font-weight: 400;
  font-size: calc(0.38 * (1.5vh + 1.1vw));
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #828282;
  ${({ theme }) => theme.adaptive.smd} {
    font-size: 14px;
  }
`

const Root = styled.div`
  position: absolute;
  left: 9%;
  top: 6.5%;
  display: flex;
  align-items: center;
  gap: calc(1.3 * (1.5vh + 1.1vw));
  font-family: 'Newtown';
  ${({ theme }) => theme.adaptive.lg} {
    top: 6%;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: 5.5%;
  }
  ${({ theme }) => theme.adaptive.smd} {
    position: fixed;
    background: url(${navigationBg}) no-repeat center;
    background-size: contain;
    width: 425px;
    height: 92px;
    z-index: 15;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    justify-content: center;
    padding-top: 11px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 332px;
    height: 72px;
    z-index: 15;
    top: -7px;
    padding-top: 5px;
  }
  .active {
    ${NavigationItem} {
      padding: 13px 49px;
      filter: drop-shadow(0px 0px 20.8531px rgba(255, 141, 79, 0.76));
      border-radius: 10px;
      border: 1px solid #ff8d4f;
      ${({ theme }) => theme.adaptive.lg} {
        padding: 10px 40px;
      }
      ${({ theme }) => theme.adaptive.slg} {
        padding: 8px 35px;
      }
    }
  }
  a {
    &:nth-child(2) {
      ${({ theme }) => theme.adaptive.smd} {
        display: none;
      }
    }
  }
`

const ExternalLink = styled.a``

const MobileButton = styled.a`
  position: relative;
  display: none;
  align-items: center;
  justify-content: center;
  width: 149px;
  height: 35px;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-size: 14px;
  padding-top: 2px;
  color: #111111;
  background: linear-gradient(
      180deg,
      rgba(255, 211, 125, 0) 0%,
      rgba(255, 211, 125, 0.5) 100%
    ),
    #ff7123;
  box-shadow: 0px 0px 17px rgba(255, 141, 79, 0.56);
  border-radius: 5px;
  ${({ theme }) => theme.adaptive.smd} {
    display: flex;
  }
  &:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background: url(${buttonLine}) repeat-x;
    left: 0;
    top: 0;
    opacity: 0.3;
    border-radius: 5px;
  }
  span {
    position: relative;
    z-index: 1;
  }
`
