import React from 'react'
import { BrowserRouter as RouterReact, Switch, Route } from 'react-router-dom'
import Index from 'components/Index'
import Monitor from 'components/Monitor/Monitor'
import Noise from 'components/Common/Noise'
import RetroCinema from 'components/Common/RetroCinema'
import useWindowSize from 'helpers/utils/useWindowSize'
import { breakpoints } from 'theme/theme'
import Navigation from 'components/Monitor/Navigation'

export default function Router() {
  const { height, width } = useWindowSize()

  const getNavigationComponent = () => {
    if (width > breakpoints.smd.w && height > breakpoints.smd.h) {
      return <Monitor />
    }
    return <Navigation />
  }

  const anchorCheck = () => {
    return window.location.href.indexOf('#tier') <= -1
  }

  return (
    <RouterReact>
      {getNavigationComponent()}
      <Noise />
      <RetroCinema />
      <Switch>
        <Route exact path="/">
          <Index isAnchor={anchorCheck()} />
        </Route>
      </Switch>
    </RouterReact>
  )
}
