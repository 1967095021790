import React, { useEffect, useRef } from 'react'
import { useStore } from 'effector-react'
import {
  $audioContext,
  $gainNode,
  $sourceBuffer,
  setBuffer,
  $isPlaying,
  setIsLoaded,
} from 'store/audio'
import styled from 'styled-components'
import { breakpoints } from 'theme/theme'

function Equalaizer() {
  const context = useStore($audioContext)
  const gainNode = useStore($gainNode)
  const source = useStore($sourceBuffer)

  const canvasRef = useRef<HTMLCanvasElement | null>(null)

  const audioVisualizerLogic = () => {
    source.loop = true

    if (!canvasRef.current || window.innerWidth <= breakpoints.smd.w) return
    fetch('/audio/music.mp3')
      .then((response) => response.arrayBuffer())
      .then((buffer) => context.decodeAudioData(buffer))
      .then((decoded) => {
        setIsLoaded(true)
        setBuffer(decoded)
        source.connect(gainNode)
        gainNode.connect(context.destination)
        if ($isPlaying.getState()) {
          source.buffer = decoded
          source.start(0)
        }
      })

    const canvas: HTMLCanvasElement = canvasRef.current

    //config canvas
    canvas.width = window.innerWidth
    canvas.height = window.innerHeight
    const ctx = canvas.getContext('2d')
    if (!ctx) return
    //config audio analyzer
    const analyser = context.createAnalyser()
    source.connect(analyser)
    analyser.connect(context.destination)
    analyser.fftSize = 128
    const bufferLength = analyser.frequencyBinCount,
      dataArray = new Uint8Array(bufferLength),
      WIDTH = canvas.width,
      HEIGHT = canvas.height,
      barWidth = (WIDTH / bufferLength) * 2.5
    let x = null
    const squaresCount = 16

    console.log(dataArray)

    //core logic for the visualizer
    // const timeouts: any = []
    const renderFrame = () => {
      requestAnimationFrame(renderFrame)
      // ctx.fillStyle = 'rgba(0,0,0,0)'
      x = 0
      analyser.getByteFrequencyData(dataArray)
      ctx.fillRect(0, 0, WIDTH, HEIGHT)
      const squareSize = WIDTH / 40
      ctx.clearRect(0, 0, WIDTH, HEIGHT)
      // ctx.fillRect(0, 0, WIDTH, HEIGHT)
      for (let i = 1; i < bufferLength; i++) {
        //color based upon frequency
        let y = 0
        if (dataArray[i] > 0) {
          const squaresPerBar = Math.round(dataArray[i] / squaresCount)
          for (let square = 1; square < squaresPerBar; square++) {
            ctx.fillStyle = `rgba(233, 148, 106, ${square / 16})`
            ctx.fillRect(x, y, squareSize, squareSize)
            y += squareSize + squareSize / 4
          }
          y = 0
        }
        x += barWidth + 1
        //Allows visualizer to overlay on a background/video by clearing the rects after painting.
        // let timer = setTimeout(() => {
        //   ctx.clearRect(0, 0, WIDTH, HEIGHT)
        // }, 50)
        // timeouts.push(timer)
      }
    }
    renderFrame()
  }

  //connect audio visualizer to DOM and execute logic
  useEffect(() => {
    audioVisualizerLogic()
    return () => console.log('destoyed')
  }, [])
  return (
    <Root>
      <canvas ref={canvasRef}></canvas>
    </Root>
  )
}

export default Equalaizer

const Root = styled.div`
  position: absolute;
  left: 810px;
  top: 149px;
  width: 253px;
  height: 200px;
  ${(props) => props.theme.adaptive.xl} {
    left: 725px;
    top: 112px;
    width: 215px;
    height: 139px;
  }
  ${(props) => props.theme.adaptive.lg} {
    left: 626px;
    top: 99px;
    width: 189px;
    height: 136px;
  }
  ${(props) => props.theme.adaptive.slg} {
    left: 545px;
    top: 99px;
    width: 170px;
    height: 110px;
  }
  ${(props) => props.theme.adaptive.md} {
    left: 421px;
    top: 106px;
    width: 160px;
    height: 119px;
  }
  canvas {
    height: 100%;
    width: 100%;
  }
`
