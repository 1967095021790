import tier1Img from '../img/tier/tier_1.png'
import tier2Img from '../img/tier/tier_2.png'
import tier3Img from '../img/tier/tier_3.png'
import tier4Img from '../img/tier/tier_4.png'
import tier5Img from '../img/tier/tier_5.png'
import tier1ImgSm from '../img/tier/tier_5-sm.png'
import tier2ImgSm from '../img/tier/tier_4-sm.png'
import tier3ImgSm from '../img/tier/tier_3-sm.png'
import tier4ImgSm from '../img/tier/tier_2-sm.png'
import tier5ImgSm from '../img/tier/tier_1-sm.png'
import { ReactComponent as TierLogoGalactica } from '../img/tier/tier-logo_galactica.svg'
import { ReactComponent as TierLogoTitanica } from '../img/tier/tier-logo_titanica.svg'
import { ReactComponent as TierLogoSpectral } from '../img/tier/tier-logo_spectral.svg'
import { ReactComponent as TierLogoViolet } from '../img/tier/tier-logo_violet.svg'
import { ReactComponent as TierLogoNeon } from '../img/tier/tier-logo_neon.svg'
import { ReactNode } from 'react'

export interface Tier {
  title: string
  name: string
  img: string
  imgSm: string
  getLogo(): ReactNode
  link: string
  description: string[]
  position: 'left' | 'right'
}

const tiers: Tier[] = [
  {
    title: 'Tier I',
    name: 'Ubik',
    img: tier5Img,
    imgSm: tier5ImgSm,
    getLogo: () => <TierLogoGalactica />,
    link: '/',
    description: [
      '3x allocation weight, guaranteed chance to participate, 37500 L2PAD',
      'proportional L2POOL mint, DAO access.',
    ],
    position: 'right',
  },
  {
    title: 'Tier II',
    name: 'Titanica',
    img: tier4Img,
    imgSm: tier4ImgSm,
    getLogo: () => <TierLogoTitanica />,
    link: '/',
    description: [
      '1.8x allocation weight, 400x tickets to participate, 12500 L2PAD',
      'proportional L2POOL mint, delegated DAO access.',
    ],
    position: 'right',
  },
  {
    title: 'Tier III',
    name: 'Spectral',
    img: tier3Img,
    imgSm: tier3ImgSm,
    getLogo: () => <TierLogoSpectral />,
    link: '/',
    description: [
      '1.5x allocation weight, 125x tickets to participate, 5000 L2PAD',
      'proportional L2POOL mint, delegated DAO access.',
    ],
    position: 'left',
  },
  {
    title: 'Tier IV',
    name: 'Ultraviolet',
    img: tier2Img,
    imgSm: tier2ImgSm,
    getLogo: () => <TierLogoViolet />,
    link: '/',
    description: [
      '1.2x allocation weight, 25x tickets to participate, 1000 L2PAD',
      'proporitonal L2POOL mint, delegated DAO access.',
    ],
    position: 'right',
  },
  {
    title: 'Tier V',
    name: 'Neon',
    img: tier1Img,
    imgSm: tier1ImgSm,
    getLogo: () => <TierLogoNeon />,
    link: '/',
    description: [
      '1x allocation weight, 1x ticket to participate, 50 L2PAD',
      'proportional L2POOL mint, no DAO access.',
    ],
    position: 'left',
  },
]

export default tiers
