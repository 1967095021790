import React from 'react'

const HomeIcon: React.FunctionComponent = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="18"
    viewBox="0 0 15 18"
    fill="none"
  >
    <path
      d="M1.26758 8.73284C1.26758 7.50182 1.26758 6.88631 1.51642 6.34528C1.76526 5.80424 2.23259 5.40367 3.16725 4.60253L4.07391 3.82539C5.7633 2.37735 6.608 1.65332 7.61418 1.65332C8.62037 1.65332 9.46506 2.37734 11.1545 3.82539L12.0611 4.60253C12.9958 5.40367 13.4631 5.80424 13.7119 6.34528C13.9608 6.88631 13.9608 7.50182 13.9608 8.73285V12.5774C13.9608 14.287 13.9608 15.1418 13.4297 15.673C12.8986 16.2041 12.0438 16.2041 10.3342 16.2041H4.89421C3.1846 16.2041 2.32979 16.2041 1.79869 15.673C1.26758 15.1418 1.26758 14.287 1.26758 12.5774V8.73284Z"
      stroke="#FF8D4F"
      stroke-width="1.81332"
    />
    <path
      d="M9.88054 16.2042V11.6709C9.88054 11.1702 9.47461 10.7642 8.97388 10.7642H6.2539C5.75317 10.7642 5.34725 11.1702 5.34725 11.6709V16.2042"
      stroke="#FF8D4F"
      stroke-width="1.81332"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export default HomeIcon
