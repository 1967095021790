import HomeIcon from '../../src/components/icons/navigation/HomeIcon'

interface Navigation {
  title: string
  path?: string
  link?: string
  getIcon?: any
}

const navigations: Navigation[] = [
  {
    title: 'Home',
    path: '/',
    getIcon: () => <HomeIcon />,
  },
  {
    title: 'FAQ',
    link: 'https://l2pad.gitbook.io/faq/',
  },
  // {
  //   title: 'Tier',
  //   path: '/tiers',
  // },
  // {
  //   title: 'Project',
  //   path: '/projects',
  // },
]

export default navigations
