import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import noiseBg from '../../img/common/noise.png'
import noiseBgPlayer from '../../img/common/noise-player.png'

interface NoiseProps {
  player?: boolean
}

const Noise: React.FunctionComponent<NoiseProps> = (props) => (
  <Root>
    <NoiseBg player={props.player} />
  </Root>
)

export default Noise

const Root = styled.div`
  pointer-events: none;
`

const NoiseAnimation = keyframes`
  0% { transform: translate(0,0) }
  10% { transform: translate(-5%,-5%) }
  20% { transform: translate(-10%,5%) }
  30% { transform: translate(5%,-10%) }
  40% { transform: translate(-5%,15%) }
  50% { transform: translate(-10%,5%) }
  60% { transform: translate(15%,0) }
  70% { transform: translate(0,10%) }
  80% { transform: translate(-15%,0) }
  90% { transform: translate(10%,5%) }
  100% { transform: translate(5%,0) }
`

const NoiseBg = styled.div<NoiseProps>`
  position: fixed;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: ${({ player }) =>
      player ? css`url(${noiseBgPlayer})` : css`url(${noiseBg})`}
    repeat 0 0;
  animation: ${NoiseAnimation} 0.2s steps(1) infinite;
  opacity: ${({ player }) => (player ? '1' : '0.09')};
  pointer-events: none;
  z-index: 2;
`
