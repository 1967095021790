import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Main from './main/Main'
import Speed from 'components/Speed'
import Tiers from 'components/Tiers'
// @ts-ignore
import { Scrollbar } from 'smooth-scrollbar-react'
import SmoothScrollbar, { ScrollbarPlugin } from 'smooth-scrollbar'
import useWindowSize from '../../helpers/utils/useWindowSize'
import { useStore } from 'effector-react'
import { setScroll, $pinElement } from 'store/scroll'
import { $audioContext, $sourceBuffer } from 'store/audio'
import Footer from '../Footer'
import MobControls from '../MobControls'
import Team from '../Team'

class DisableScrollPlugin extends ScrollbarPlugin {
  static pluginName = 'disableScroll'

  static defaultOptions = {
    direction: null,
  }

  transformDelta(delta: any) {
    if (this.options.direction) {
      delta[this.options.direction] = 0
    }

    return { ...delta }
  }
}

class ModalPlugin extends ScrollbarPlugin {
  static pluginName = 'modal'

  static defaultOptions = {
    open: false,
  }

  transformDelta(delta: any) {
    return this.options.open ? { x: 0, y: 0 } : delta
  }
}

SmoothScrollbar.use(DisableScrollPlugin)
SmoothScrollbar.use(ModalPlugin)

interface indexProps {
  isAnchor: boolean
}

const Index: React.FunctionComponent<indexProps> = (props) => {
  const scrollbarRef = useRef(null)
  const [isAnchor, setIsAnchor] = useState(props.isAnchor)
  const pinElement = useStore($pinElement)
  const audioContext = useStore($audioContext)

  const source = useStore($sourceBuffer)
  const { width, height } = useWindowSize()

  useEffect(() => {
    console.log(pinElement)
  }, [pinElement])

  useEffect(() => {
    if (!isAnchor) {
      const elem = document.querySelector('#tier')
      // @ts-ignore
      const elemHeight = elem.offsetHeight
      const offsetTop = (height - elemHeight) / 2
      // @ts-ignore
      scrollbarRef.current.scrollIntoView(elem, {
        offsetTop: offsetTop,
      })
    }
  }, [isAnchor])

  const startAudio = () => {
    if (source.start) {
      console.log(source)
      try {
        source.start()
      } catch (error) {
        console.log(error)
      }
      // @ts-ignore
    } else if (source.play) {
      // @ts-ignore
      console.log(source.play(0))
      // @ts-ignore
      source.play(0)
      // @ts-ignore
    } else if (source.noteOn) {
      // @ts-ignore
      source.noteOn(0)
    }
  }

  const closePreLoader = () => {
    startAudio()
    audioContext.resume()
  }

  const handleScroll = ({ offset }: any) => {
    const pin = $pinElement.getState()
    if (pin) {
      if (offset.y <= pin.offsetHeight / 2) {
        pin.style = `
            transform: translateY(${offset.y}px);
        `
      }
    }
    setScroll(offset)
  }

  if (typeof window === 'undefined' || !window.document) {
    return null
  }

  return (
    <Scrollbar
      ref={scrollbarRef}
      damping={0.08}
      onScroll={handleScroll}
      plugins={{
        disableScroll: { direction: 'x' },
        //modal: { open: width >= 1049 ? preloader : false },
      }}
    >
      <Root>
        <Main />
        <MobControls />
        <Speed />
        <Tiers />
        <Team />
        <Footer />
      </Root>
    </Scrollbar>
  )
}

export default Index

const Root = styled.div`
  position: relative;
  /* width: 100vw; */
  /* height: 100vh; */
  background: #1a1315;
  /* overflow: auto; */
  /* overflow: hidden; */
  max-height: 100vh;
  width: 100vw;
  overflow: hidden;
`
