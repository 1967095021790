import React from 'react'
import styled from 'styled-components'
import socialsList from '../../store/socials'

const Socials: React.FunctionComponent = () => {
  return (
    <Wrap>
      {socialsList.map((social, idx) => {
        return (
          <SocialLink href={social.link} target="_blank" key={idx}>
            <social.icon />
          </SocialLink>
        )
      })}
    </Wrap>
  )
}

export default Socials

const Wrap = styled.div`
  display: none;
  ${(props) => props.theme.adaptive.smd} {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`

const SocialLink = styled.a`
  ${(props) => props.theme.adaptive.smd} {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 54px;
    height: 54px;
    border: 1px solid rgba(255, 255, 255, 0.19);
    border-radius: 4px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    width: 42px;
    height: 42px;
    border-radius: 3px;
  }
  svg {
    ${(props) => props.theme.adaptive.smd} {
      display: block;
      max-width: 16px;
      max-height: 16px;
      fill: #fff;
    }
    ${(props) => props.theme.adaptive.ssm} {
      max-width: 12px;
      max-height: 12px;
    }
  }
`
