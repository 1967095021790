import { createStore, createEvent } from 'effector'

interface scrollInterface {
  x: number
  y: number
}

export const $scroll = createStore<scrollInterface>({ x: 0, y: 0 })
export const $pinElement = createStore<any>(null)

export const setScroll = createEvent<scrollInterface>()
export const setPinElement = createEvent<any | any>()

$scroll.on(setScroll, (state, scroll: scrollInterface) => scroll)
$pinElement.on(setPinElement, (state, pinElement) => pinElement)
