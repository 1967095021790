import React, { useRef, useState } from 'react'
import MonitorImage from 'components/Monitor/MonitorImage'
import styled, { css } from 'styled-components'
import Controls from './Controls'
import Navigation from './Navigation'
import Logo from 'components/Logo'

interface ResizeProps {
  width: number
  height: number
}

function Monitor() {
  const imageWrapperRef = useRef<any>(null)
  const [resizeTimeout, setReszieTimeout] = useState<any>(null)

  const handleResize = ({ width, height }: ResizeProps): void => {
    clearTimeout(resizeTimeout)
    setReszieTimeout(
      setTimeout(() => {
        imageWrapperRef.current.style.width = `${width}px`
        imageWrapperRef.current.style.height = `${height}px`
      }, 100)
    )
  }

  const handleImageCreate = ({ width, height }: ResizeProps) => {
    imageWrapperRef.current.style.width = `${width}px`
    imageWrapperRef.current.style.height = `${height}px`
  }

  return (
    <Root>
      <ImageWrapper ref={imageWrapperRef}>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <ActiveArea>
          <Controls />
          <Navigation />
        </ActiveArea>
        <MonitorAround />
        <MonitorAroundSides />
        {/* <Copyright>© L2PAD, All rights can't be protected, 2021</Copyright> */}
      </ImageWrapper>
      <MonitorImageWrapper hidden={true}>
        <MonitorImage onResize={handleResize} onCreate={handleImageCreate} />
      </MonitorImageWrapper>
    </Root>
  )
}

export default Monitor

const Root = styled.div`
  font-family: 'Newtown';
  position: fixed;
  display: flex;
  justify-content: center;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  pointer-events: none;
`

const ActiveArea = styled.div`
  pointer-events: all;
`

const LogoWrapper = styled.div`
  position: absolute;
  right: 21%;
  top: 17%;
`

interface MonitorImageWrapperProps {
  hidden: boolean
}

const MonitorImageWrapper = styled.div<MonitorImageWrapperProps>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-bottom: 77%; */
  /* this is your height/width ratio */
  /* height: 0; */
  svg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
  }
`
const ImageWrapper = styled.div`
  position: relative;
  /* height: fit-content; */
  width: 100%;
  height: 100%;
  /* height: auto; */
  margin: auto;
`

const around = css`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
`

const MonitorAround = styled.div`
  ${around}
  &:before,
  &:after {
    content: '';
    position: absolute;
    background: #100f10;
    width: 100%;
    height: 40vh;
    left: 0;
  }
  &:before {
    top: 99%;
  }
  &:after {
    bottom: 99%;
  }
`

const MonitorAroundSides = styled.div`
  ${around}
  &:before,
  &:after {
    content: '';
    position: absolute;
    background: #100f10;
    width: 40vw;
    height: 100%;
    top: 0;
  }
  &:before {
    left: 100%;
  }
  &:after {
    right: 100%;
  }
`

const Copyright = styled.p`
  position: absolute;
  bottom: 3.9%;
  right: 3.5%;
  margin: 0;
  color: #4e4a4b;
  letter-spacing: 0.065em;
  font-size: calc(0.4 * (1.5vh + 1.1vw));
`
