import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import bgDark from 'img/index/main/bg-dark.jpg'
import bgLight from 'img/index/main/bg-light.jpg'
import { gsap } from 'gsap'
import { Container, getAdaptiveShortname } from 'theme/theme'
import Timer from './Timer/Timer'
import Logotype from 'components/Logo'
import { $scroll, setPinElement } from 'store/scroll'
import { useStore } from 'effector-react'
import useWindowSize from '../../../helpers/utils/useWindowSize'
// @ts-ignore

function Main() {
  const [isSticky, setIsSticky] = useState(false)
  const scroll = useStore($scroll)

  const darkBackgroundRef = useRef<any>()
  const lightBackgroundRef = useRef<any>()
  const rootRef = useRef<any>()
  const { width } = useWindowSize()

  const yAdaptive: any = {
    max: 100,
    xl: 90,
    lg: 80,
    slg: 65,
    md: 50,
    smd: 0,
  }

  useEffect(() => {
    if (rootRef.current) {
      setPinElement(rootRef.current)
    }
  }, [rootRef])

  useEffect(() => {
    const height = rootRef.current.offsetHeight
    if (scroll.y >= height / 2) {
    } else {
      const alpha = scroll.y / (height / 2)
      const adaptiveShortname = getAdaptiveShortname(window.innerWidth)
      const y = yAdaptive[adaptiveShortname] - alpha * 300
      const x = adaptiveShortname === 'smd' ? 'right' : 0
      const backgroundPosition = `${x} ${y}px`
      gsap.to(lightBackgroundRef.current, {
        opacity: 1 - alpha,
        backgroundPosition,
      })
      gsap.to(darkBackgroundRef.current, {
        opacity: alpha,
        backgroundPosition,
      })
    }
  }, [scroll])

  return (
    <div>
      <RootWrapper>
        <Root ref={rootRef}>
          <BackgroundImage
            id="darkImage"
            style={{ backgroundImage: `url(${bgDark})` }}
            ref={darkBackgroundRef}
          />
          <BackgroundImage
            id="lightImage"
            style={{ backgroundImage: `url(${bgLight})` }}
            ref={lightBackgroundRef}
          />
          <Container>
            <TextWrapper>
              {/*<LogoWrapper>*/}
              {/*  <Logotype />*/}
              {/*</LogoWrapper>*/}
              <Title>
                layer<span>2</span> pad
              </Title>
              <Description>
                Quadratic VC DAO <span>and</span> {width > 1049 && <br />}{' '}
                Public Goods Launchpad
              </Description>
            </TextWrapper>
            <Timer />
          </Container>
        </Root>
      </RootWrapper>
    </div>
  )
}

export default Main

const RootWrapper = styled.div`
  position: relative;
  height: 150vh;
`

const Root = styled.div`
  position: relative;
  bottom: 0;
  /* top: 0; */
  height: 100vh;
  width: 85%;
  background: #322d39;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  transform: translateY(0);
  ${({ theme }) => theme.adaptive.smd} {
    width: 100%;
  }
  ${({ theme }) => theme.adaptive.sm} {
    height: 100%;
    max-height: 100vh;
  }
  ${Container} {
    ${({ theme }) => theme.adaptive.smd} {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding: 105px 0;
    }
    ${({ theme }) => theme.adaptive.sm} {
      padding: 105px 0 25px 0;
    }
  }
`

// const LogoWrapper = styled.div`
//   display: none;
//   ${({ theme }) => theme.adaptive.sm} {
//     display: block;
//     margin-bottom: 20px;
//   }
//   ${({ theme }) => theme.adaptive.ssm} {
//     display: block;
//     margin-bottom: 15px;
//   }
// `

const BackgroundImage = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-position: bottom -130px right !important;
  background-size: cover;
  background-repeat: no-repeat;
  ${({ theme }) => theme.adaptive.smd} {
    background-position: top left !important;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.sm} {
    background-position: top left -300px !important;
    background-size: 1050px;
  }
`

const TextWrapper = styled.div`
  position: relative;
  z-index: 11;
  margin-bottom: calc(0.3 * (17vh + 17vw));
  ${(props) => props.theme.adaptive.lg} {
    margin-bottom: 65px;
  }
  ${(props) => props.theme.adaptive.slg} {
    margin-bottom: 55px;
  }
  ${(props) => props.theme.adaptive.smd} {
    margin-bottom: 70px;
  }
  ${(props) => props.theme.adaptive.sm} {
    width: 273px;
    margin: 0 auto;
  }
  ${(props) => props.theme.adaptive.ssm} {
    width: 213px;
  }
`

const Title = styled.span`
  display: block;
  font-family: 'Rexagon';
  text-transform: uppercase;
  font-size: 147px;
  color: #fff;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 130px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 110px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 95px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 71px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    font-size: 90px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 73px;
    line-height: 80px;
    text-align: center;
  }
  ${({ theme }) => theme.adaptive.ssm} {
    font-size: 57px;
    line-height: 63px;
  }
  ${({ theme }) => theme.adaptive.xsm} {
    font-size: 56px;
  }
  span {
    background: linear-gradient(to bottom, #e77141, #dea268);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0px 0px 28.7744px rgba(227, 139, 86, 0.62),
      0px 0px 5.23171px rgba(227, 139, 86, 0.62);
  }
`

const Description = styled.h1`
  font-size: 38px;
  line-height: 46px;
  color: #fff;
  font-weight: 400;
  letter-spacing: 0.02em;
  margin: 0;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 33px;
    line-height: 40px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 28px;
    line-height: 34px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 24px;
    line-height: 29px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 18px;
    line-height: 22px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    text-align: center;
    font-size: 23px;
    line-height: 28px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 24px;
    line-height: 29px;
  }
  ${({ theme }) => theme.adaptive.ssm} {
    font-size: 18px;
    line-height: 22px;
  }
  ${({ theme }) => theme.adaptive.xsm} {
    font-size: 19px;
    line-height: 23px;
  }
  span {
    color: #ec844e;
  }
`
