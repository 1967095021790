import React from 'react'
import styled from 'styled-components'
import borderImg from 'img/mobcontrols/border.svg'
import borderImgSm from 'img/mobcontrols/border-sm.svg'
import Logotype from 'components/Logo'
import Socials from 'components/Socials'
import { DECK_LINK, MANIFESTO_LINK } from '../../configs/app'

const MobControls: React.FunctionComponent = () => {
  return (
    <Root>
      <Wrapper>
        <Container>
          <LogoWrap>
            <Logotype />
          </LogoWrap>
          <ButtonWrap onClick={() => window.open(DECK_LINK, '_blank')}>
            <Button>download INVESTMENT DECK</Button>
            <BtnLight />
            <LightSource />
          </ButtonWrap>
          <ButtonWrap
            colorRgb={'126, 188, 255'}
            onClick={() => window.open(MANIFESTO_LINK, '_blank')}
          >
            <Button>download L2DAO Manifesto</Button>
            <BtnLight />
            <LightSource />
          </ButtonWrap>
          <SocialWrap>
            <Socials />
          </SocialWrap>
        </Container>
      </Wrapper>
    </Root>
  )
}

export default MobControls

interface colorProps {
  colorRgb?: string
}

const Root = styled.div`
  display: none;
  ${(props) => props.theme.adaptive.smd} {
    display: block;
    padding: 50px 0 86px;
  }
  ${(props) => props.theme.adaptive.sm} {
    padding: 24px 0 76px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    padding: 24px 0 60px;
  }
`

const Wrapper = styled.div`
  ${(props) => props.theme.adaptive.smd} {
    position: relative;
    width: 500px;
    height: 530px;
    margin: 0 auto;
    background: #101011;
    box-shadow: 0px 25px 43px rgba(0, 0, 0, 0.75);
    border-radius: 23px;
  }
  ${(props) => props.theme.adaptive.sm} {
    width: 384px;
    height: 530px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    width: 300px;
    height: 414px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 9px;
    left: 10px;
    width: 480px;
    height: 511px;
    background: url('${borderImg}') center no-repeat;
    background-size: cover;
    ${(props) => props.theme.adaptive.sm} {
      left: 7px;
      width: 370px;
      height: 511px;
      background: url('${borderImgSm}') center no-repeat;
      background-size: cover;
    }
    ${(props) => props.theme.adaptive.ssm} {
      top: 7px;
      width: 289px;
      height: 399px;
    }
  }
`

const Container = styled.div`
  ${(props) => props.theme.adaptive.smd} {
    padding: 55px 0;
    width: 333px;
    margin: 0 auto;
  }
  ${(props) => props.theme.adaptive.ssm} {
    padding: 50px 0;
    width: 250px;
  }
`

const LogoWrap = styled.div`
  ${(props) => props.theme.adaptive.smd} {
    width: 179px;
    margin: 0 auto;
    margin-bottom: 42px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    width: 140px;
    margin-bottom: 33px;
  }
`

const BtnLight = styled.div<colorProps>`
  ${(props) => props.theme.adaptive.smd} {
    width: 100%;
    height: 14px;
    filter: blur(13px);
    border-radius: 3px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    display: none;
  }
`

const LightSource = styled.div`
  ${(props) => props.theme.adaptive.smd} {
    width: 84px;
    height: 4px;
    margin: 0 auto;
    margin-top: 4px;
    border-radius: 6px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    width: 66px;
    height: 3px;
    border-radius: 5px;
    margin-top: 16px;
  }
`

const ButtonWrap = styled.div<colorProps>`
  ${(props) => props.theme.adaptive.smd} {
    width: 320px;
    margin: 0 auto;

    &:nth-child(3) {
      margin-top: 54px;
    }
  }
  ${(props) => props.theme.adaptive.ssm} {
    width: 250px;
    margin-top: 39px;
  }
  ${BtnLight} {
    background: ${({ colorRgb }) =>
      colorRgb ? `rgba(${colorRgb}, 0.2)` : `rgba(255, 141, 79, 0.2)`};
    box-shadow: ${({ colorRgb }) =>
      colorRgb
        ? `0px 0px 2px rgba(${colorRgb}, 0.5)`
        : `0px 0px 2px rgba(255, 141, 79, 0.5)`};
  }
  ${LightSource} {
    background: ${({ colorRgb }) =>
      colorRgb ? `rgba(${colorRgb}, 1)` : `rgba(255, 141, 79, 1)`};
    box-shadow: ${({ colorRgb }) =>
      colorRgb
        ? `0px 0px 9px rgba(${colorRgb}, 0.9)`
        : `0px 0px 9px rgba(255, 141, 79, 0.9)`};
  }
`

const Button = styled.a`
  ${(props) => props.theme.adaptive.smd} {
    display: block;
    width: 100%;
    height: 64px;
    padding: 24px;
    font-weight: 600;
    font-size: 15px;
    line-height: 151%;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #ffffff;
    opacity: 0.5;
    border-top: 1px solid #60616a;
    border-bottom: 1px solid #222126;
    background: #1a1b1e;
    border-radius: 6px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    border-radius: 5px;
    height: 50px;
    padding: 16px;
    font-size: 12px;
  }
`

const SocialWrap = styled.div`
  ${(props) => props.theme.adaptive.smd} {
    width: 233px;
    margin: 49px auto 0;
  }
  ${(props) => props.theme.adaptive.ssm} {
    width: 182px;
    margin: 38px auto 0;
  }
  a {
    width: 44px;
    height: 44px;
    ${(props) => props.theme.adaptive.ssm} {
      width: 34px;
      height: 34px;
    }
  }
  svg {
    display: block;
    width: 16px;
    height: 13px;
    ${(props) => props.theme.adaptive.ssm} {
      width: 15px;
      height: 12px;
    }
  }
`
