import React from 'react'
import styled, { css } from 'styled-components'
import { Tier as TierProps } from 'store/tiersList'
import useWindowSize from '../../helpers/utils/useWindowSize'

interface Props {
  data: TierProps
  active?: boolean
}

function Tier(props: Props) {
  const { width } = useWindowSize()
  const { data, active = false } = props

  return (
    <Root
      active={active}
      style={{
        backgroundImage: `url(${width <= 619 ? data.imgSm : data.img})`,
      }}
      position={data.position}
    >
      <LogoWrapper tier={data.name.toLowerCase()}>{data.getLogo()}</LogoWrapper>
      <Text>
        <Row>
          <Title>
            {data.title} — {data.name}
          </Title>
          {/* <Info>More info</Info> */}
        </Row>
        {data.description &&
          data.description.map((descr, idx) => (
            <Description key={idx}>{descr}</Description>
          ))}
      </Text>
    </Root>
  )
}

export default Tier

interface LogoWrapperProps {
  tier: string
}

const LogoWrapper = styled.div<LogoWrapperProps>`
  position: absolute;
  ${({ tier }) => {
    switch (tier) {
      case 'ubik':
        return css`
          width: 108px;
          height: 21px;
          left: 27px;
          bottom: 23px;
          ${(props) => props.theme.adaptive.xl} {
            width: 85px;
            height: 17px;
            left: 23px;
            bottom: 19px;
          }
          ${(props) => props.theme.adaptive.lg} {
            width: 75px;
            height: 12px;
            left: 20px;
            bottom: 21px;
          }
          ${(props) => props.theme.adaptive.slg} {
            width: 65px;
            height: 10px;
            left: 16px;
            bottom: 18px;
          }
          ${(props) => props.theme.adaptive.md} {
            width: 60px;
            height: 9px;
            left: 13px;
            bottom: 17px;
          }
          ${(props) => props.theme.adaptive.smd} {
            width: 81px;
            height: 14px;
            left: 23px;
            bottom: 23px;
          }
          ${(props) => props.theme.adaptive.sm} {
            top: 152px;
            width: 72px;
            height: 15px;
            left: 61px;
          }
          ${(props) => props.theme.adaptive.ssm} {
            top: 126px;
            width: 64px;
            height: 15px;
            left: 50px;
          }
        `
      case 'titanica':
        return css`
          width: 96px;
          height: 24px;
          left: 66px;
          bottom: 51px;
          ${(props) => props.theme.adaptive.xl} {
            width: 87px;
            height: 21px;
            left: 54px;
            bottom: 45px;
          }
          ${(props) => props.theme.adaptive.lg} {
            width: 79px;
            height: 21px;
            left: 46px;
            bottom: 36px;
          }
          ${(props) => props.theme.adaptive.slg} {
            width: 71px;
            height: 19px;
            left: 37px;
            bottom: 29px;
          }
          ${(props) => props.theme.adaptive.md} {
            width: 65px;
            height: 19px;
            left: 28px;
            bottom: 20px;
          }
          ${(props) => props.theme.adaptive.smd} {
            width: 99px;
            height: 25px;
            left: 54px;
            bottom: 48px;
          }
          ${(props) => props.theme.adaptive.sm} {
            top: 137px;
            width: 109px;
            height: 17px;
            left: 43px;
          }
          ${(props) => props.theme.adaptive.ssm} {
            top: 114px;
            width: 89px;
            height: 22px;
            left: 38px;
          }
        `
      case 'spectral':
        return css`
          width: 116px;
          height: 25px;
          right: 27px;
          top: 50px;
          ${(props) => props.theme.adaptive.xl} {
            width: 109px;
            height: 22px;
            right: 23px;
            top: 46px;
          }
          ${(props) => props.theme.adaptive.lg} {
            width: 99px;
            height: 22px;
            right: 23px;
            top: 36px;
          }
          ${(props) => props.theme.adaptive.slg} {
            width: 87px;
            height: 20px;
            right: 17px;
            top: 29px;
          }
          ${(props) => props.theme.adaptive.md} {
            width: 75px;
            height: 18px;
            right: 15px;
            top: 23px;
          }
          ${(props) => props.theme.adaptive.smd} {
            width: 109px;
            height: 22px;
            right: 23px;
            top: 46px;
          }
          ${(props) => props.theme.adaptive.sm} {
            top: 31px;
            width: 109px;
            height: 17px;
            left: 46px;
          }
          ${(props) => props.theme.adaptive.ssm} {
            top: 22px;
            width: 91px;
            height: 19px;
            left: 60px;
          }
        `
      case 'ultraviolet':
        return css`
          width: 101px;
          height: 46px;
          left: 55px;
          top: 58px;
          ${(props) => props.theme.adaptive.xl} {
            width: 89px;
            height: 41px;
            left: 50px;
            top: 56px;
          }
          ${(props) => props.theme.adaptive.lg} {
            width: 79px;
            height: 39px;
            left: 42px;
            top: 40px;
          }
          ${(props) => props.theme.adaptive.slg} {
            width: 69px;
            height: 32px;
            left: 35px;
            top: 34px;
          }
          ${(props) => props.theme.adaptive.md} {
            width: 57px;
            height: 27px;
            left: 29px;
            top: 28px;
          }
          ${(props) => props.theme.adaptive.smd} {
            width: 98px;
            height: 41px;
            left: 50px;
            top: 52px;
          }
          ${(props) => props.theme.adaptive.sm} {
            width: 86px;
            height: 41px;
            left: 61px;
            top: 140px;
          }
          ${(props) => props.theme.adaptive.ssm} {
            width: 56px;
            height: 30px;
            left: 58px;
            top: 120px;
          }
        `
      case 'neon':
        return css`
          width: 103px;
          height: 27px;
          right: 67px;
          bottom: 44px;
          ${(props) => props.theme.adaptive.xl} {
            width: 91px;
            height: 22px;
            right: 57px;
            bottom: 43px;
          }
          ${(props) => props.theme.adaptive.lg} {
            width: 76px;
            height: 22px;
            right: 49px;
            bottom: 32px;
          }
          ${(props) => props.theme.adaptive.slg} {
            width: 69px;
            height: 21px;
            right: 40px;
            bottom: 25px;
          }
          ${(props) => props.theme.adaptive.md} {
            width: 65px;
            height: 16px;
            right: 31px;
            bottom: 23px;
          }
          ${(props) => props.theme.adaptive.smd} {
            width: 95px;
            height: 23px;
            right: 61px;
            bottom: 43px;
          }
          ${(props) => props.theme.adaptive.sm} {
            width: 86px;
            height: 41px;
            left: 55px;
            top: 148px;
          }
          ${(props) => props.theme.adaptive.ssm} {
            width: 66px;
            height: 17px;
            left: 50px;
            top: 124px;
          }
        `
    }
  }}
`

interface RootProps {
  active: boolean
  position: 'left' | 'right'
}

const Text = styled.div`
  width: 279px;
  ${(props) => props.theme.adaptive.xl} {
    width: 229px;
  }
  ${(props) => props.theme.adaptive.lg} {
    width: 197px;
  }
  ${(props) => props.theme.adaptive.slg} {
    width: 173px;
  }
  ${(props) => props.theme.adaptive.md} {
    width: 142px;
  }
  ${(props) => props.theme.adaptive.smd} {
    width: 253px;
  }
  ${(props) => props.theme.adaptive.sm} {
    width: 165px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    width: 125px;
  }
`

const Root = styled.div<RootProps>`
  position: relative;
  width: 100%;
  height: 270px;
  display: ${({ active }) => (active ? 'block' : 'none')};
  padding-top: 70px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  ${({ position }) => {
    if (position === 'left') {
      return css`
        padding-left: 44px;
        ${(props) => props.theme.adaptive.xl} {
          padding-left: 38px;
        }
        ${(props) => props.theme.adaptive.lg} {
          padding-left: 32px;
        }
        ${(props) => props.theme.adaptive.slg} {
          padding-left: 28px;
        }
        ${(props) => props.theme.adaptive.md} {
          padding-left: 23px;
        }
        ${(props) => props.theme.adaptive.smd} {
          padding-left: 39px;
        }
        ${(props) => props.theme.adaptive.sm} {
          padding-left: 15px;
          padding-right: 0;
        }
        ${(props) => props.theme.adaptive.ssm} {
          padding-left: 13px;
        }
      `
    }
    if (position === 'right') {
      return css`
        padding-right: 44px;
        ${(props) => props.theme.adaptive.xl} {
          padding-right: 38px;
        }
        ${(props) => props.theme.adaptive.lg} {
          padding-right: 32px;
        }
        ${(props) => props.theme.adaptive.slg} {
          padding-right: 28px;
        }
        ${(props) => props.theme.adaptive.md} {
          padding-right: 23px;
        }
        ${(props) => props.theme.adaptive.smd} {
          padding-right: 39px;
        }
        ${(props) => props.theme.adaptive.sm} {
          padding-left: 15px;
          padding-right: 0;
        }
        ${(props) => props.theme.adaptive.ssm} {
          padding-left: 13px;
        }
        ${Text} {
          margin-left: auto;
          ${(props) => props.theme.adaptive.sm} {
            margin-left: 0;
          }
        }
      `
    }
  }}
  ${(props) => props.theme.adaptive.xl} {
    height: 237px;
    padding-top: 66px;
  }
  ${(props) => props.theme.adaptive.lg} {
    height: 203px;
    padding-top: 56px;
  }
  ${(props) => props.theme.adaptive.slg} {
    height: 175px;
    padding-top: 48px;
  }
  ${(props) => props.theme.adaptive.md} {
    height: 145px;
    padding-top: 40px;
  }
  ${(props) => props.theme.adaptive.smd} {
    height: 251px;
    padding-top: 69px;
  }
  ${(props) => props.theme.adaptive.sm} {
    width: 209px;
    height: 409px;
    padding-top: 190px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    width: 174px;
    height: 340px;
    padding-top: 153px;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 13px;
  ${(props) => props.theme.adaptive.xl} {
    padding-bottom: 12px;
  }
  ${(props) => props.theme.adaptive.lg} {
    padding-bottom: 10px;
  }
  ${(props) => props.theme.adaptive.slg} {
    padding-bottom: 9px;
  }
  ${(props) => props.theme.adaptive.md} {
    padding-bottom: 7px;
  }
  ${(props) => props.theme.adaptive.smd} {
    padding-bottom: 12px;
  }
  ${(props) => props.theme.adaptive.sm} {
    display: block;
    padding-bottom: 28px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    padding-bottom: 19px;
  }
`

const Title = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  letter-spacing: 0.02em;
  ${(props) => props.theme.adaptive.xl} {
    font-size: 16px;
  }
  ${(props) => props.theme.adaptive.lg} {
    font-size: 14px;
  }
  ${(props) => props.theme.adaptive.slg} {
    font-size: 13px;
  }
  ${(props) => props.theme.adaptive.md} {
    font-size: 11px;
  }
  ${(props) => props.theme.adaptive.smd} {
    font-size: 19px;
  }
  ${(props) => props.theme.adaptive.sm} {
    width: 100%;
    font-size: 15px;
    margin-bottom: 14px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    font-size: 14px;
    margin-bottom: 12px;
  }
`

const Info = styled.span`
  font-size: 14px;
  letter-spacing: 0.02em;
  color: #b9b9ba;
  ${(props) => props.theme.adaptive.xl} {
    font-size: 13px;
  }
  ${(props) => props.theme.adaptive.lg} {
    font-size: 11px;
  }
  ${(props) => props.theme.adaptive.slg} {
    font-size: 10px;
  }
  ${(props) => props.theme.adaptive.md} {
    font-size: 8px;
  }
  ${(props) => props.theme.adaptive.smd} {
    font-size: 14px;
  }
  ${(props) => props.theme.adaptive.sm} {
    font-size: 12px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    font-size: 13px;
  }
`

const Description = styled.p`
  position: relative;
  font-size: 15px;
  line-height: 21px;
  color: #c6c6c8;
  padding-left: 22px;
  padding-top: 11px;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  margin: 0;
  margin-bottom: 9px;
  ${(props) => props.theme.adaptive.xl} {
    font-size: 13px;
    line-height: 18px;
    padding-left: 20px;
    padding-top: 10px;
    margin-bottom: 8px;
  }
  ${(props) => props.theme.adaptive.lg} {
    font-size: 12px;
    line-height: 16px;
    padding-left: 17px;
    padding-top: 8px;
    margin-bottom: 7px;
  }
  ${(props) => props.theme.adaptive.slg} {
    font-size: 11px;
    line-height: 14px;
    padding-left: 14px;
    padding-top: 7px;
    margin-bottom: 6px;
  }
  ${(props) => props.theme.adaptive.md} {
    font-size: 9px;
    line-height: 11px;
    padding-left: 12px;
    padding-top: 6px;
    margin-bottom: 5px;
  }
  ${(props) => props.theme.adaptive.smd} {
    font-size: 16px;
    line-height: 19px;
    padding-left: 21px;
    padding-top: 10px;
    margin-bottom: 8px;
  }
  ${(props) => props.theme.adaptive.sm} {
    font-size: 13px;
    line-height: 16px;
    padding-left: 17px;
    padding-top: 8px;
    margin-bottom: 7px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    font-size: 13px;
    line-height: 13px;
    padding-left: 14px;
    padding-top: 7px;
    margin-bottom: 6px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 18px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid #ec7f30;
    ${(props) => props.theme.adaptive.xl} {
      top: 14px;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 6px solid #ec7f30;
    }
    ${(props) => props.theme.adaptive.lg} {
      top: 12px;
    }
    ${(props) => props.theme.adaptive.slg} {
      top: 10px;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 5px solid #ec7f30;
    }
    ${(props) => props.theme.adaptive.md} {
      top: 8px;
    }
    ${(props) => props.theme.adaptive.smd} {
      top: 12px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 7px solid #ec7f30;
    }
    ${(props) => props.theme.adaptive.sm} {
      top: 10px;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 6px solid #ec7f30;
    }
    ${(props) => props.theme.adaptive.ssm} {
      top: 9px;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 5px solid #ec7f30;
    }
  }
`
