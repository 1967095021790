import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import {
  $volume,
  $buffer,
  setVolume,
  $sourceBuffer,
  $audioContext,
  setIsPlaying,
  $isPlaying,
  $isLoaded,
} from 'store/audio'
import { useStore } from 'effector-react'
import { ReactComponent as MuteIcon } from 'img/volumeMeter/mute-icon.svg'
import { ReactComponent as UnmuteIcon } from 'img/volumeMeter/unmute-icon.svg'
import { ReactComponent as Play } from 'img/volumeMeter/play-img.svg'
import { ReactComponent as Pause } from 'img/volumeMeter/pause-img.svg'

const VolumeMeter: React.FunctionComponent = () => {
  const volume = useStore($volume)
  const sourceBuffer = useStore($sourceBuffer)
  const audioContext = useStore($audioContext)
  const buffer = useStore($buffer)
  const isPlaying = useStore($isPlaying)
  const isLoaded = useStore($isLoaded)

  const onChangeVolume = () => (e: { target: { valueAsNumber: any } }) => {
    setVolume(e.target.valueAsNumber)
  }

  const onMute = () => {
    setVolume(0)
  }

  const onUnMute = () => {
    setVolume(-1)
  }

  const playToggle = () => {
    setIsPlaying(!isPlaying)
    if ($isLoaded.getState() && !sourceBuffer.buffer) {
      sourceBuffer.buffer = buffer
      return sourceBuffer.start()
    }
    audioContext.state === 'running'
      ? audioContext.suspend()
      : audioContext.resume()
  }

  return (
    <Root>
      <Buttons onClick={playToggle} disabled={isPlaying && !isLoaded}>
        {!isPlaying ? (
          <>
            <BtnText>{'Play'}</BtnText>
            <BtnIcon>
              <Play />
            </BtnIcon>
          </>
        ) : (
          <>
            <BtnText>{'Pause'}</BtnText>
            <BtnIcon>
              <Pause />
            </BtnIcon>
          </>
        )}
      </Buttons>
      <MuteButton onClick={onMute}>
        <MuteIcon />
      </MuteButton>
      <VolumeInput
        type="range"
        min={0}
        max={1}
        step={0.01}
        value={Math.abs(volume)}
        onChange={onChangeVolume()}
      />
      <UnMuteButton onClick={onUnMute}>
        <UnmuteIcon />
      </UnMuteButton>
    </Root>
  )
}

export default VolumeMeter

export const Root = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const VolumeInput = styled.input`
  -webkit-appearance: none;
  width: calc(0.42 * (10vh + 10vw));
  height: calc(0.025 * (10vh + 10vw));
  box-shadow: inset 3px 1px 2px rgba(0, 0, 0, 0.27);
  border-radius: 3px;
  background: #000000;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  &::-webkit-slider-thumb {
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    width: calc(0.08 * (10vh + 10vw));
    height: calc(0.08 * (10vh + 10vw));
    border-radius: 50%;
    border-color: rgba(154, 97, 52, 1);
    border-style: solid;
    border-width: 2px;
    background: #252525;
    box-shadow: 1.81332px 1.81332px 2.71997px rgba(0, 0, 0, 0.25),
      inset 3.62663px 3.62663px 3.12886px rgba(58, 68, 93, 0.5),
      inset -3.62663px -3.62663px 3.12886px rgba(6, 12, 24, 0.69);
    cursor: pointer;
    &:hover {
      border-color: #ff8d4f;
    }
  }
`

const regulateVolumeIconsStyle = css`
  outline: none;
  height: calc(0.06 * (10vh + 10vw));
  svg {
    opacity: 0.3;
    transition: opacity 0.3s ease;
  }
  &:hover {
    svg {
      opacity: 1;
    }
  }
`

const MuteButton = styled.button`
  ${regulateVolumeIconsStyle}
  width: calc(0.065 * (10vh + 10vw));
  margin-right: 6px;
`

const UnMuteButton = styled.button`
  ${regulateVolumeIconsStyle}
  width: calc(0.07 * (10vh + 10vw));
  margin-left: 6px;
`

const Buttons = styled.button`
  font-family: 'Newtown';
  position: absolute;
  top: 50%;
  right: calc(100%);
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: 0.35;
  ${(props) => props.theme.adaptive.md} {
    right: calc(100% + (0.08 * (2vh + 2vw)));
  }
  &:hover {
    opacity: 1;
  }
  &:disabled {
    opacity: 0.35;
  }
`

const BtnIcon = styled.div`
  width: calc(0.076 * (10vh + 10vw));
  height: calc(0.076 * (10vh + 10vw));
  svg {
    width: 100%;
    height: 100%;
  }
`

const BtnText = styled.div`
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ff8d4f;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.35);
  font-size: calc(0.46 * (1.5vh + 1.1vw));
  margin-right: calc(0.045 * (10vh + 10vw));
`
