import styled from 'styled-components'

export const breakpoints = {
  xl: {
    w: 1879,
    h: 939,
  },
  lg: {
    w: 1679,
    h: 769,
  },
  slg: {
    w: 1479,
    h: 689,
  },
  md: {
    w: 1279,
    h: 589,
  },
  smd: {
    w: 1049,
    h: 509,
  },
  sm: {
    w: 619,
  },
  ssm: {
    w: 415,
  },
  xsm: {
    w: 380,
  },
}

const theme = {
  adaptive: {
    xl: `@media screen and (max-width: ${breakpoints.xl.w}px), screen and (max-height: ${breakpoints.xl.h}px)`,
    lg: `@media screen and (max-width: ${breakpoints.lg.w}px), screen and (max-height: ${breakpoints.lg.h}px)`,
    slg: `@media screen and (max-width: ${breakpoints.slg.w}px), screen and (max-height: ${breakpoints.slg.h}px)`,
    md: `@media screen and (max-width: ${breakpoints.md.w}px), screen and (max-height: ${breakpoints.md.h}px)`,
    smd: `@media screen and (max-width: ${breakpoints.smd.w}px), screen and (max-height: ${breakpoints.smd.h}px)`,
    sm: `@media screen and (max-width: ${breakpoints.sm.w}px)`,
    ssm: `@media screen and (max-width: ${breakpoints.ssm.w}px)`,
    xsm: `@media screen and (max-width: ${breakpoints.ssm.w}px)`,
  },
}

export default theme

export const getAdaptiveShortname = (windowWidth: number): string => {
  if (windowWidth <= 830) return 'sm'
  else if (windowWidth <= breakpoints.smd.w) return 'smd'
  else if (windowWidth <= breakpoints.md.w) return 'md'
  else if (windowWidth <= breakpoints.slg.w) return 'slg'
  else if (windowWidth <= breakpoints.lg.w) return 'lg'
  else if (windowWidth <= breakpoints.xl.w) return 'xl'
  else return 'max'
}

export const Container = styled.div`
  position: relative;
  z-index: 9;
  width: 1360px;
  margin: 0 auto;
  ${theme.adaptive.xl} {
    width: 1220px;
  }
  ${theme.adaptive.lg} {
    width: 1060px;
  }
  ${theme.adaptive.slg} {
    width: 920px;
  }
  ${theme.adaptive.md} {
    width: 780px;
  }
  ${theme.adaptive.smd} {
    width: 500px;
  }
  ${theme.adaptive.sm} {
    width: 100%;
    padding: 0 15px;
  }
`
