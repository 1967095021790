import getTime from 'date-fns/getTime'

export default function getPercentage(startDate: Date, endDate: Date): number {
  let start = getTime(startDate)
  let end = getTime(endDate)
  const todayDate = getTime(new Date())
  if (end > todayDate) {
    const total = end - start
    const current = todayDate - start

    return (current / total) * 100
  } else {
    return 100
  }
}
