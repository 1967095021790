import React from 'react'
import styled, { css } from 'styled-components'
import { noop } from 'lodash'
import buttonLine from 'img/mobcontrols/btn-line.svg'

type Color = 'blue' | 'green' | 'yellow' | 'light'

interface Props {
  name: string
  color?: Color
  icon?: string
  disabled?: boolean
  onClick(): void
}

function Button(props: Props) {
  const { name, disabled = false, onClick = noop, color = 'yellow' } = props

  return (
    <Root disabled={disabled} color={color} onClick={onClick}>
      <Name>{name}</Name>
      <ButtonWrapper></ButtonWrapper>
      <Shadow />
      <Indicator />
    </Root>
  )
}

export default Button

const Shadow = styled.div`
  width: 61px;
  height: 4px;
  background: rgba(255, 141, 79, 0.2);
  box-shadow: 0px 0px 1.66154px rgba(255, 141, 79, 0.5);
  filter: blur(10px);
  border-radius: 2px;
  margin: 0 auto;
  margin-bottom: calc(0.15 * (2vh + 2vw));
`

const Indicator = styled.div`
  width: 17px;
  height: 3px;
  background: #ff8d4f;
  box-shadow: 0px 0px 7px rgba(255, 141, 79, 0.9);
  border-radius: 5px;
  margin: 0 auto;
  ${(props) => props.theme.adaptive.slg} {
    width: 14px;
  }
`

const ButtonWrapper = styled.div`
  width: calc(0.3 * (10vh + 10vw));
  height: calc(0.22 * (7vh + 7vw));
  border-top: 1px solid #60616a;
  border-bottom: 1px solid #222126;
  background: linear-gradient(
      0deg,
      rgba(16, 16, 17, 0.9),
      rgba(16, 16, 17, 0.9)
    ),
    #3e3f46;
  border-radius: 5px;
  margin: 0 auto;
  margin-bottom: calc(0.018 * (10vh + 10vw));
`

const Name = styled.span`
  display: inline-block;
  letter-spacing: 0.02em;
  color: #878788;
  font-size: 12px;
  line-height: 150%;
  max-width: calc(0.43 * (10vh + 10vw));
  margin: 0 auto;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: calc(0.04 * (10vh + 10vw));
  font-size: calc(0.325 * (1.5vh + 1.1vw));
`

export const Root = styled.button`
  font-family: 'Newtown';
  text-align: center;
  margin: 0 auto;
  ${({ color }) => {
    switch (color) {
      case 'blue':
        return css`
          ${Shadow} {
            background: rgba(126, 188, 255, 0.2);
            box-shadow: 0px 0px 1.66154px rgba(126, 188, 255, 0.5);
          }
          ${Indicator} {
            background: #7ebcff;
            box-shadow: 0px 0px 7px rgba(126, 188, 255, 0.9);
          }
        `
      case 'green':
        return css`
          ${Shadow} {
            background: rgba(131, 211, 91, 0.2);
            box-shadow: 0px 0px 1.66154px rgba(131, 211, 91, 0.5);
          }
          ${Indicator} {
            background: #83d35b;
            box-shadow: 0px 0px 7px rgba(131, 211, 91, 0.9);
          }
        `
      case 'light':
        return css`
          ${ButtonWrapper} {
            position: relative;
            background: linear-gradient(
                180deg,
                rgba(255, 211, 125, 0) 0%,
                rgba(255, 211, 125, 0.5) 100%
              ),
              #ff7123;
            box-shadow: 0px 0px 17px rgba(255, 141, 79, 0.56);
            border-bottom: 1px solid #db7e4b;
            border-top: 0;
            &:before {
              content: '';
              position: absolute;
              height: 100%;
              width: calc(100% - 10px);
              background: url(${buttonLine});
              left: 5px;
              top: 0;
              opacity: 0.3;
            }
          }
          ${Name} {
            color: #ff8d4f;
          }
          ${Shadow} {
            background: rgba(255, 141, 79, 0.2);
            box-shadow: 0px 0px 1.66154px rgba(255, 141, 79, 0.5);
          }
          ${Indicator} {
            background: #ffe7c4;
            box-shadow: 0px 0px 7px rgba(255, 141, 79, 0.9);
          }
        `
    }
  }}
  &:hover {
    ${({ disabled, color }) => {
      if (color === 'light') {
        return css`
          ${ButtonWrapper} {
            background: linear-gradient(
                180deg,
                rgba(255, 211, 125, 0) 0%,
                rgba(255, 211, 125, 0.5) 100%
              ),
              #ff9431;
          }
        `
      }
      if (!disabled) {
        return css`
          ${ButtonWrapper} {
            background: #1a1b1e;
          }
          ${Indicator} {
            background: #ffc086;
          }
          ${() => {
            switch (color) {
              case 'blue':
                return css`
                  ${Indicator} {
                    background: #9acbff;
                  }
                `
              case 'green':
                return css`
                  ${Indicator} {
                    background: #baff97;
                  }
                `
            }
          }}
        `
      }
    }}
  }
  ${({ disabled }) => {
    if (disabled)
      return css`
        cursor: not-allowed;
        ${Shadow} {
          opacity: 0;
        }
        ${Indicator} {
          background: linear-gradient(180deg, #404049 0%, #2c2d33 100%);
          box-shadow: 0px -2px 0px #191c22;
        }
      `
  }}
`
