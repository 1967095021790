import React from 'react'
import styled, { keyframes } from 'styled-components'
import loaderList from '../../../../store/loaderList'
import getPercentage from '../../../../helpers/getPercentage'
import Decimal from 'decimal.js'
import { START_DATE, END_DATE } from 'configs/app'

function TimerImage() {
  const getCount = () => {
    let percentage = getPercentage(START_DATE, END_DATE)
    if (percentage < 100 && percentage > 95) {
      return 21
    } else return Math.ceil((percentage * 22) / 100)
  }

  return (
    <Root>
      <svg
        width="398"
        height="45"
        viewBox="0 0 398 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_887:508)">
          <path
            opacity="0.6"
            d="M378.835 32.7H0.545106C-0.243004 32.7 -0.721404 31.8308 -0.299715 31.1649L18.3203 1.76495C18.5036 1.47549 18.8224 1.3 19.1651 1.3H397.455C398.243 1.3 398.721 2.16923 398.3 2.83505L379.68 32.2351C379.496 32.5245 379.178 32.7 378.835 32.7Z"
            stroke="white"
            strokeWidth="1.4"
          />
          <path opacity="0.07" d="M7 29H19L34 5H22L7 29Z" fill="white" />
          <path opacity="0.07" d="M24 29H36L51 5H39L24 29Z" fill="white" />
          <path opacity="0.07" d="M41 29H53L68 5H56L41 29Z" fill="white" />
          <path opacity="0.07" d="M58 29H70L85 5H73L58 29Z" fill="white" />
          <path opacity="0.07" d="M75 29H87L102 5H90L75 29Z" fill="white" />
          <path opacity="0.07" d="M92 29H104L119 5H107L92 29Z" fill="white" />
          <path opacity="0.07" d="M109 29H121L136 5H124L109 29Z" fill="white" />
          <path opacity="0.07" d="M126 29H138L153 5H141L126 29Z" fill="white" />
          <path opacity="0.07" d="M143 29H155L170 5H158L143 29Z" fill="white" />
          <path opacity="0.07" d="M160 29H172L187 5H175L160 29Z" fill="white" />
          <path opacity="0.07" d="M177 29H189L204 5H192L177 29Z" fill="white" />
          <path opacity="0.07" d="M194 29H206L221 5H209L194 29Z" fill="white" />
          <path opacity="0.07" d="M211 29H223L238 5H226L211 29Z" fill="white" />
          <path opacity="0.07" d="M228 29H240L255 5H243L228 29Z" fill="white" />
          <path opacity="0.07" d="M245 29H257L272 5H260L245 29Z" fill="white" />
          <path opacity="0.07" d="M262 29H274L289 5H277L262 29Z" fill="white" />
          <path opacity="0.07" d="M279 29H291L306 5H294L279 29Z" fill="white" />
          <path opacity="0.07" d="M296 29H308L323 5H311L296 29Z" fill="white" />
          <path opacity="0.07" d="M313 29H325L340 5H328L313 29Z" fill="white" />
          <path opacity="0.07" d="M330 29H342L357 5H345L330 29Z" fill="white" />
          <path opacity="0.07" d="M347 29H359L374 5H362L347 29Z" fill="white" />
          <path opacity="0.07" d="M364 29H376L391 5H379L364 29Z" fill="white" />
          <path
            d="M6 41.9311L0.999825 39L0.999826 44.8623L6 41.9311Z"
            fill="#665E5D"
          />
          <path
            d="M10 42L177 42"
            stroke="#665E5D"
            strokeWidth="0.6"
            strokeDasharray="8 8"
          />
          <path
            d="M199 42L369 42"
            stroke="#665E5D"
            strokeWidth="0.6"
            strokeDasharray="8 8"
          />
          <path
            d="M191 41.9311L186 39L186 44.8623L191 41.9311Z"
            fill="#665E5D"
          />
          <path
            d="M378 41.9311L373 39L373 44.8623L378 41.9311Z"
            fill="#665E5D"
          />
          <g>
            {loaderList.map((item, idx) => {
              if (new Decimal(idx).lessThanOrEqualTo(getCount()))
                return (
                  <path
                    key={idx}
                    d={item.path}
                    fill="url(#paint0_linear_887:508)"
                    className={getCount() === idx ? 'animation' : ''}
                  />
                )
            })}
          </g>
        </g>
        <defs>
          <filter
            id="filter0_dd_887:508"
            x="-39.1973"
            y="-42"
            width="119.395"
            height="118"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="23.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.909804 0 0 0 0 0.517647 0 0 0 0 0.32549 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_887:508"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.909804 0 0 0 0 0.517647 0 0 0 0 0.32549 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="effect1_dropShadow_887:508"
              result="effect2_dropShadow_887:508"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect2_dropShadow_887:508"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_887:508"
            x1="36"
            y1="7"
            x2="6.99999"
            y2="29"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E16F41" />
            <stop offset="1" stopColor="#F8BF89" />
          </linearGradient>
          <clipPath id="clip0_887:508">
            <rect width="398" height="45" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Root>
  )
}

export default TimerImage

const tick = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const Root = styled.div`
  .animation {
    animation: ${tick} 2s linear infinite;
  }
`
