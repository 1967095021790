import React, { useEffect, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import Bg from '../../img/speed/background.png'
import bgMd from '../../img/speed/background-smd-new.svg'
import BgSm from '../../img/speed/background-sm.png'
import bgMobileLoader from '../../img/speed/loader-smd-new.png'
import loader from 'img/speed/loader.svg'
import gsap from 'gsap'
import { Container, breakpoints } from 'theme/theme'
import Equalaizer from './Equalaizer'
import animBg from '../../img/speed/anim-bg.svg'
import animBgSmd from '../../img/speed/anim-bg-smd.svg'
import { useStore } from 'effector-react'
import { $audioContext } from '../../store/audio'
import Texts from '../../store/speedList'
import speedList from '../../store/speedList'
import useWindowSize from 'helpers/utils/useWindowSize'

const Speed: React.FunctionComponent = () => {
  const context = useStore($audioContext)
  const textRef = React.useRef(null)
  const [activeIdx, setActiveIdx] = useState(0)
  const { width } = useWindowSize()

  useEffect(() => {
    FadeIn(textRef.current)
  }, [activeIdx])

  const FadeOut = (elem: gsap.TweenTarget) => {
    gsap.fromTo(
      elem,
      { y: 0, opacity: 1 },
      {
        delay: 0.8,
        y: -50,
        opacity: 0,
        duration: 1,
        ease: 'expo.in',
        onComplete: () => {
          if (activeIdx < Texts.length - 1) {
            setActiveIdx(activeIdx + 1)
          } else {
            setActiveIdx(0)
          }
        },
      }
    )
  }

  const FadeIn = (elem: gsap.TweenTarget) => {
    gsap.fromTo(
      elem,
      {
        y: 50,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 1,
        ease: 'circ.inOut',
        onComplete: () => {
          FadeOut(elem)
        },
      }
    )
  }

  return (
    <RootWrapper>
      <Root>
        <Container>
          {context && <Equalaizer />}
          {width > breakpoints.smd.w && <LoaderWrapper />}
          <Content>
            <AnimWrapper>
              <AnimText ref={textRef}>{Texts[activeIdx].text}</AnimText>
            </AnimWrapper>
            <Dots>
              <Rectangle />
              <DotWrapper>
                <Line>
                  <LineProgress />
                </Line>
              </DotWrapper>
              {speedList.map((item, idx) => (
                <DotWrapper key={idx}>
                  <Dot active={idx === activeIdx}>
                    <Number active={idx === activeIdx}>{idx + 1}</Number>
                  </Dot>
                  <Line>
                    <LineProgress active={idx === activeIdx} />
                  </Line>
                </DotWrapper>
              ))}
              <Rectangle />
            </Dots>
          </Content>
        </Container>
      </Root>
      <MobileLoader>
        <LoaderWrapper />
      </MobileLoader>
    </RootWrapper>
  )
}

export default Speed

interface activeProps {
  active?: boolean
}

const Content = styled.div`
  width: 313px;
  margin-left: 377px;
  ${(props) => props.theme.adaptive.xl} {
    width: 256px;
    margin-left: 370px;
  }
  ${(props) => props.theme.adaptive.lg} {
    width: 227px;
    margin-left: 307px;
  }
  ${(props) => props.theme.adaptive.slg} {
    width: 203px;
    margin-left: 261px;
  }
  ${(props) => props.theme.adaptive.md} {
    width: 182px;
    margin-left: 161px;
  }
  ${(props) => props.theme.adaptive.smd} {
    width: 493px;
    margin: 0 auto;
  }
  ${(props) => props.theme.adaptive.sm} {
    width: 340px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    width: 253px;
  }
`

const AnimWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 26px;
  align-items: center;
  background: url('${animBg}') center no-repeat;
  background-size: cover;
  ${(props) => props.theme.adaptive.xl} {
    height: 21px;
  }
  ${(props) => props.theme.adaptive.lg} {
    height: 19px;
  }
  ${(props) => props.theme.adaptive.slg} {
    height: 17px;
  }
  ${(props) => props.theme.adaptive.md} {
    height: 15px;
  }
  ${(props) => props.theme.adaptive.smd} {
    background: url('${animBgSmd}') center no-repeat;
    height: 83px;
  }
  ${(props) => props.theme.adaptive.sm} {
    height: 56px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    height: 42px;
  }
`

const AnimText = styled.div`
  font-family: 'Helios';
  width: 100%;
  text-align: center;
  font-size: 21px;
  line-height: 21px;
  text-transform: uppercase;
  color: #e88555;
  ${(props) => props.theme.adaptive.xl} {
    font-size: 18px;
    line-height: 18px;
  }
  ${(props) => props.theme.adaptive.lg} {
    font-size: 15px;
    line-height: 15px;
  }
  ${(props) => props.theme.adaptive.slg} {
    font-size: 14px;
    line-height: 14px;
  }
  ${(props) => props.theme.adaptive.md} {
    font-size: 13px;
    line-height: 13px;
  }
  ${(props) => props.theme.adaptive.smd} {
    font-size: 34px;
    line-height: 40px;
  }
  ${(props) => props.theme.adaptive.sm} {
    font-size: 24px;
    line-height: 24px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    font-size: 18px;
    line-height: 18px;
  }
`

const LoaderWrapper = styled.div`
  position: absolute;
  right: 32px;
  top: 156px;
  width: 35px;
  height: 35px;
  background: url(${loader}) no-repeat center;
  ${(props) => props.theme.adaptive.xl} {
    right: 62px;
    top: 132px;
    width: 29px;
    height: 29px;
  }
  ${(props) => props.theme.adaptive.lg} {
    right: 50px;
    top: 117px;
    width: 26px;
    height: 26px;
  }
  ${(props) => props.theme.adaptive.slg} {
    right: 33px;
    top: 108px;
    width: 21px;
    height: 21px;
  }
  ${(props) => props.theme.adaptive.md} {
    right: 43px;
    top: 100px;
    width: 21px;
    height: 21px;
  }
  ${(props) => props.theme.adaptive.smd} {
    right: 82px;
    top: 226px;
    width: 74px;
    height: 74px;
  }
  ${(props) => props.theme.adaptive.sm} {
    right: 57px;
    top: 160px;
    width: 52px;
    height: 52px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    right: 49px;
    top: 119px;
    width: 39px;
    height: 39px;
  }
`

const DotWrapper = styled.div`
  display: flex;
  flex: 1 1 0%;
`

const Dots = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 3px;
  ${(props) => props.theme.adaptive.smd} {
    margin-top: 6px;
  }
  ${(props) => props.theme.adaptive.sm} {
    margin-top: 5px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    margin-top: 4px;
  }
`

const Rectangle = styled.div`
  width: 2px;
  height: 4px;
  background: #633e29;
  ${(props) => props.theme.adaptive.smd} {
    width: 3px;
    height: 6px;
  }
  ${(props) => props.theme.adaptive.sm} {
    width: 2px;
    height: 4px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    width: 2px;
    height: 3px;
  }
`

const Line = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 0%;
  height: 1px;
  background: rgba(235, 118, 79, 0.4);
  margin: 0 5px;
  ${(props) => props.theme.adaptive.smd} {
    margin: 0 8px;
    height: 2px;
  }
  ${(props) => props.theme.adaptive.sm} {
    margin: 0 6px;
    height: 1px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    margin: 0 4px;
  }
`

const ProgressAnim = keyframes`
  0% { width: 0%; }
  100% { width: 100%;}
`

const LineProgress = styled.div<activeProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background: rgba(235, 118, 79, 1);
  animation: ${({ active }) =>
    active
      ? css`
          ${ProgressAnim}
        `
      : ''};
  animation-iteration-count: 1;
  animation-duration: 2.8s;
`

const Dot = styled.div<activeProps>`
  position: relative;
  width: 1px;
  height: 2px;
  background: ${({ active }) => (active ? '#EB774F' : '#633E29')};
  ${(props) => props.theme.adaptive.smd} {
    width: 2px;
    height: 3px;
  }
  ${(props) => props.theme.adaptive.sm} {
    width: 1px;
    height: 2px;
  }
`

const Number = styled.div<activeProps>`
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 7px;
  line-height: 8px;
  color: ${({ active }) => (active ? '#EB774F' : '#633E29')};
  ${(props) => props.theme.adaptive.smd} {
    font-size: 8px;
    line-height: 13px;
  }
  ${(props) => props.theme.adaptive.sm} {
    font-size: 8px;
    line-height: 9px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    font-size: 8px;
    line-height: 9px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    font-size: 6px;
    line-height: 7px;
  }
`

const MobileLoader = styled.div`
  position: relative;
  width: 500px;
  height: 601px;
  z-index: 4;
  margin: 0 auto;
  display: none;
  ${(props) => props.theme.adaptive.smd} {
    display: block;
  }
  ${(props) => props.theme.adaptive.sm} {
    width: 333px;
    height: 425px;
  }
  ${(props) => props.theme.adaptive.ssm} {
    width: 260px;
    height: 317px;
  }
  &:before {
    content: '';
    position: absolute;
    right: -312px;
    top: 0;
    width: 860px;
    height: 601px;
    background: url(${bgMobileLoader}) no-repeat center;
    ${(props) => props.theme.adaptive.sm} {
      right: -221px;
      width: 608px;
      height: 425px;
      background-size: cover;
    }
    ${(props) => props.theme.adaptive.ssm} {
      right: -158px;
      width: 453px;
      height: 317px;
    }
  }
`

const RootWrapper = styled.div`
  position: relative;
  ${(props) => props.theme.adaptive.smd} {
    margin-bottom: 65px;
  }
`

const Root = styled.div`
  position: relative;
  width: 85%;
  padding: 154px 0 367px;
  ${(props) => props.theme.adaptive.xl} {
    padding: 122px 0 320px;
  }
  ${(props) => props.theme.adaptive.lg} {
    padding: 105px 0 275px;
  }
  ${(props) => props.theme.adaptive.slg} {
    padding: 101px 0 236px;
  }
  ${(props) => props.theme.adaptive.md} {
    padding: 87px 0 175px;
  }
  ${(props) => props.theme.adaptive.smd} {
    width: 100%;
    padding: 8px 0 65px;
  }
  ${(props) => props.theme.adaptive.sm} {
    padding: 6px 0 50px 0;
  }
  ${(props) => props.theme.adaptive.ssm} {
    width: 260px;
    margin: 0 auto;
    padding: 4px 0 40px 0;
  }
  &:before {
    content: '';
    position: absolute;
    top: -230px;
    left: 50%;
    transform: translateX(-39%);
    width: 1905px;
    height: 935px;
    background: url('${Bg}') center no-repeat;
    z-index: 4;
    ${(props) => props.theme.adaptive.xl} {
      top: -144px;
      width: 1673px;
      height: 675px;
      background-size: contain;
    }
    ${(props) => props.theme.adaptive.lg} {
      top: -171px;
      width: 1421px;
      height: 679px;
    }
    ${(props) => props.theme.adaptive.slg} {
      top: -149px;
      width: 1265px;
      height: 617px;
    }
    ${(props) => props.theme.adaptive.md} {
      top: -112px;
      width: 1166px;
      height: 508px;
      transform: translateX(-43%);
    }
    ${(props) => props.theme.adaptive.smd} {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 684px;
      height: 101px;
      background: url('${bgMd}') center no-repeat;
      background-size: contain;
    }
    ${(props) => props.theme.adaptive.sm} {
      width: 482px;
      height: 70px;
    }
    ${(props) => props.theme.adaptive.ssm} {
      width: 360px;
      height: 52px;
    }
  }
  ${Container} {
    padding: 0;
  }
`
